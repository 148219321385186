import apiWrapper from '@/core/api/apiWrapper';
import { apiHelperV4 } from '@/Helpers/apiHelper';
import { TrizettoPayload, EligibilityResponse } from './types';

export class EligibilityRequest {
  public constructor(
    private options: {
      payload: TrizettoPayload;
      timeout?: number;
    }
  ) {
    this.options.timeout = this.options.timeout || 15000;
  }

  public async submit(): Promise<EligibilityResponse> {
    return apiWrapper.post(`${apiHelperV4()}eligibility/verify-member`, this.options.payload, {
      timeout: this.options.timeout,
    });
  }

  /**
   * Returns true / false as to whether the error is caused by datadome.
   */
  public static isDataDomeResponse(err: any) {
    // Header only present if datadome provides the response
    if (err?.response?.headers && err.response.headers['x-dd-b']) {
      return true;
    }
    return false;
  }

  /**
   * Returns true / false as to whether the error is the result of datadome doing a check.
   */
  public static isTimeoutError(err: any) {
    if (
      err.message &&
      (err.message.match(/timeout of \w*ms exceeded/) || err.message.match(/408/))
    ) {
      return true;
    }
    return false;
  }

  public static isVerificationFailureError(err: any) {
    return (
      err.response?.data?.message ===
      'Unable to verify eligibility at this time. Please try again later'
    );
  }

  public static getErrorType(err: Error) {
    if (EligibilityRequest.isTimeoutError(err)) {
      return 'TIMEOUT';
    }
    if (EligibilityRequest.isVerificationFailureError(err)) {
      return 'VERIFICATION_FAILURE';
    }
    if (EligibilityRequest.isDataDomeResponse(err)) {
      return 'DATADOME_RESPONSE';
    }
    return 'OTHER';
  }
}

export const submitEligibilityRequest = (
  payload: TrizettoPayload,
  {
    timeout,
  }: {
    timeout?: number;
  } = {}
) =>
  new EligibilityRequest({
    payload,
    timeout,
  }).submit();
