import { US_STATES_THAT_REQUIRE_VIDEO_SESSION_BEFORE_OTHER_MODALITIES as videoFirstStates } from '@talkspace/configs';
import {
  AudioModality,
  ChatModality,
  MessagingModality,
  VideoModality,
} from '@talkspace/react-toolkit';
import { TranslationKey } from '@talkspace/i18n/types';
import { FunctionComponent } from 'react';
import { SessionModality } from 'ts-frontend/types';
import { HomePageState } from '../Components/HomePage/types';

export const shouldSkipOrRemovePreferredModality = (state: HomePageState) =>
  !!(
    !state.allowedModalities ||
    state.allowedModalities.length === 0 ||
    (state.clientState && state.clientState in videoFirstStates) ||
    (state.service !== 'psychotherapy' &&
      state.service !== 'therapyCouples' &&
      state.service !== 'therapyTeen')
  );

export const modalitiesIconAndText: Record<
  SessionModality,
  {
    Icon: FunctionComponent<any>;
    description: string;
    descriptionTranslationKey: TranslationKey;
    displayTitle: string;
    displayTitleTranslationKey: TranslationKey;
  }
> = {
  video: {
    Icon: VideoModality,
    description: 'Connect face-to-face via video',
    descriptionTranslationKey: 'sessionModalityStepSelectModality.videoDescriptionText',
    displayTitle: 'Live Video',
    displayTitleTranslationKey: 'sessionModalityStepSelectModality.videoTitleText',
  },
  audio: {
    Icon: AudioModality,
    description: 'Speak through voice only',
    descriptionTranslationKey: 'sessionModalityStepSelectModality.audioDescriptionText',
    displayTitle: 'Live Audio',
    displayTitleTranslationKey: 'sessionModalityStepSelectModality.audioTitleText',
  },
  chat: {
    Icon: ChatModality,
    description: 'Chat in real time, receive instant responses',
    descriptionTranslationKey: 'sessionModalityStepSelectModality.chatDescriptionText',
    displayTitle: 'Live Chat',
    displayTitleTranslationKey: 'sessionModalityStepSelectModality.chatTitleText',
  },
  messaging: {
    Icon: MessagingModality,
    description: 'Write at your own pace, receive responses daily, 5 days per week',
    descriptionTranslationKey: 'sessionModalityStepSelectModality.messagingDescriptionText',
    displayTitle: 'Messaging Session',
    displayTitleTranslationKey: 'sessionModalityStepSelectModality.messagingTitleText',
  },
};
