import { useState } from 'react';
import * as React from 'react';
import {
  ExtraHuge,
  Input,
  Large,
  Button,
  BaseButton,
  Standard,
  View,
  Mini,
  useWindowWidthState,
  useEmotionTheme,
  useUniqueID,
  useErrorFocus,
} from '@talkspace/react-toolkit';
import { useHistory } from 'react-router';
import validateEmail from 'ts-frontend/utils/validateEmail';
import { useInputChange } from '../../hooks/useInputChange';
import styled from '../../core/styled';

export interface EligibilityEmailProps {
  email: string;
  isLoading: boolean;
  onSubmit: (value: string) => void;
}

const Container = styled(View)({ '&:focus': { outline: 'none' } });

const EligibilityEmail: React.VFC<EligibilityEmailProps> = ({ email, isLoading, onSubmit }) => {
  const history = useHistory();
  const [input, handleInputChange] = useInputChange(email);
  const [isInputValid, setIsInputValid] = useState(true);
  const { isMobile } = useWindowWidthState();
  const titleID = useUniqueID('header1Id');
  const header2Id = useUniqueID('header2Id');
  const notSharedTextId = useUniqueID('notSharedTextId');
  const { colors } = useEmotionTheme();
  const { formContainerRef, setShouldFocusFirstInvalidField } = useErrorFocus();

  const emailEmpty = input.length === 0;

  const onSubmitEditing = () => {
    if (emailEmpty || !isInputValid) return;
    onSubmit(input);
  };

  const onSecondaryButtonPress = () => {
    history.goBack();
  };

  return (
    <Container ref={formContainerRef} aria-labelledby={titleID} tabIndex={-1}>
      <View style={{ marginBottom: 35, maxWidth: isMobile ? 320 : undefined }}>
        <ExtraHuge as="h1" style={{ marginBottom: 28 }}>
          Enter your email address
        </ExtraHuge>
        <Large id={header2Id} as="h2" variant="largeDarkGrey" style={{ fontWeight: 400 }}>
          Please enter the email address you use with your employer or organization providing
          Talkspace as a benefit.
        </Large>
      </View>
      <View style={{ marginBottom: 40, maxWidth: isMobile ? 320 : undefined }}>
        <Input
          type="email"
          autoFocus
          dataQa="emailInput"
          ariaDescribedBy={`${header2Id} ${notSharedTextId}`}
          ariaRequired
          wrappedInputProps={{
            label: 'Email',
            errorMessage: 'Invalid email address',
            isError: !isInputValid,
            containerStyle: { width: '100%' },
            inputStyle: { width: '100%', color: colors.black },
            wrapperStyle: { width: '100%', borderColor: colors.periwinkleGrey },
          }}
          placeholder="Email"
          value={input}
          onChange={handleInputChange}
        />
        <Mini id={notSharedTextId} style={{ marginTop: 5, textAlign: 'left' }}>
          This information will not be shared with your employer.
        </Mini>
      </View>
      {!emailEmpty && (
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          style={{ backgroundColor: colors.green, width: '100%' }}
          primaryColor={colors.green}
          roundedFocusStyle
          onPress={(e) => {
            if (e) e.preventDefault();
            const isEmail = validateEmail(input);
            if (isEmail) {
              setShouldFocusFirstInvalidField(false);
              setIsInputValid(true);
              onSubmitEditing();
            } else {
              setShouldFocusFirstInvalidField(true);
              setIsInputValid(false);
            }
          }}
        >
          Next
        </Button>
      )}
      <BaseButton
        onPress={onSecondaryButtonPress}
        style={{ borderRadius: 5 }}
        primaryColor={colors.gray}
        roundedFocusStyle
      >
        <Standard variant="standardDarkGrey" style={{ marginTop: 17, alignSelf: 'center' }}>
          Return
        </Standard>
      </BaseButton>
    </Container>
  );
};

export default EligibilityEmail;
