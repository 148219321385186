import { states } from '../Helpers/locales';
import { EligibilityType, QMFlow } from './types';
import {
  initFlowConfig,
  skipIfExperiment,
  skipToStepIfClientAgeExists,
  skipToStepIfClientAgeIsTeen,
  skipToStepIfDoesntMeetAvailabilityPreferencesRequirements,
} from './utils';
import { HomePageState, AnswerValue, AccountType } from '../Components/HomePage/types';
import { shouldSkipOrRemovePreferredModality } from '../Helpers/preferredModalityHelper';
// eslint-disable-next-line import/no-cycle
import { getClientFlowConfig } from './index';

const skipStepsForPsychiatryBH = (state: HomePageState) => {
  // skip a few steps for BH psychiatry users
  const { service } = state;
  if (service === 'psychiatry') {
    if (state.clientAge) {
      // skip dob step if coming from another flow
      return 11;
    }
    return 10;
  }
  return undefined;
};

const promptLastingPromotion = (_state: HomePageState, answer: AnswerValue) => {
  // prompt Lasting promotion if url has `lasting=1` and relationship presenting problem is selected
  const urlParams = new URLSearchParams(window.location.search);
  // response_value 20: I'm having difficulty in my relationship
  if (Number(urlParams.get('lasting')) === 1 && Number(answer.response_value) === 20) {
    return 3;
  }
  return undefined;
};

const skipModalitySelectionIfIrrelevant = (state: HomePageState) => {
  if (shouldSkipOrRemovePreferredModality(state)) {
    return 19;
  }

  return undefined;
};

const skipReviewPlanIfIrrelevant = (state: HomePageState) => {
  const { accountType } = state;
  const isPsychiatry = state?.service === 'psychiatry';
  const manualFlowID = state?.manualFlowID;

  const manualFlowConfig = manualFlowID ? getClientFlowConfig(manualFlowID) : undefined;
  const isManualFlow =
    manualFlowConfig?.eligibilityType === EligibilityType.manual ||
    getClientFlowConfig(state.redirectFrom)?.eligibilityType === EligibilityType.manual;

  if (
    accountType &&
    [AccountType.EAP, AccountType.BH].includes(accountType) &&
    !isManualFlow &&
    !isPsychiatry
  ) {
    return undefined;
  }
  return skipModalitySelectionIfIrrelevant(state) || 30; // go to modalities
};

/** Generic B2B (with voucher) */
export const FLOW_2_B2B_VOUCHER_GENERIC = 2;

const flow: QMFlow = {
  flowId: FLOW_2_B2B_VOUCHER_GENERIC,
  flowConfig: {
    ...initFlowConfig,
    isB2B: true,
    version: 3,
  },
  steps: [
    {
      id: 1,
      prompt: 'Welcome to QuickMatch',
      analyticsId: 53,
      category: 'introduction1',
      response_category_id: 1,
      progressCurrent: 0,
      progressMax: 10,
      heading1: 'Welcome to <br/>Talkspace QuickMatch <sup>TM</sup>',
      heading2:
        "In the next 90 seconds you'll learn everything you need to know about how Talkspace works and get matched with a provider that can help you.",
      buttonText: "Let's start!",
      buttonTarget: 2,
    },
    {
      id: 2,
      prompt: 'What led you to seek help?',
      analyticsId: 54,
      progressCurrent: 1,
      response_category_id: 2,
      progressMax: 10,
      heading1: null,
      heading2: 'To begin, please select why you thought about getting help from a provider',
      category: 'presentingProblems',
      internalTarget: 135,
      selfServe: {
        field: 'fieldsOfExpertise',
        type: Array,
      },
      nextQuestionActions: [promptLastingPromotion, skipStepsForPsychiatryBH],
      tip: 'findTheRightMatch',
    },
    {
      id: 135,
      category: 'memberAvailability',
      prompt: 'When are you generally available for therapy?',
      heading2: 'When are you generally available for therapy?',
      skipQuestionActions: [
        skipToStepIfDoesntMeetAvailabilityPreferencesRequirements(4),
        skipIfExperiment({
          experimentName: 'clientAvailabilityPreferencesExperiment',
          variant: 'control',
          nextStepId: 4,
          shouldTrack: true,
        }),
      ],
      buttonTarget: 4,
    },
    {
      id: 3,
      prompt: 'Talkspace now offers guided couples therapy',
      analyticsId: 101,
      heading1: null,
      heading2: 'Talkspace now offers guided couples therapy',
      category: 'lastingPromotion',
      response_category_id: 7,
      progressCurrent: 2,
      progressMax: 10,
      externalTarget: 'https://lastingappstore.onelink.me/yxnG/f3c8f830',
      internalTarget: 4,
    },
    {
      id: 4,
      prompt: 'Here are things to know about Therapy',
      analyticsId: 56,
      category: 'explanation',
      progressCurrent: 2,
      progressMax: 10,
      response_category_id: 1,
      heading1: null,
      heading2: 'There are two important things to know about how Talkspace works',
      explanationGroups: [
        {
          text: 'You can send your provider text, audio and video messages whenever you want.',
          tooltipText:
            'This means that you will be communicating with your provider by typing in a secure chat room. Online therapy is a clinically valid and convenient way of working with a provider.',
        },
        {
          text: 'Your provider is available to engage daily, 5 days a week.',
          tooltipText:
            "You can write into the secure chat room whenever you feel you’re ready. You don't have to wait for your next appointment. If you need more or less, you can always arrange it with your provider.",
        },
      ],
      buttonText: 'Got it!',
      buttonTarget: 5,
    },
    {
      id: 5,
      prompt: 'Preferred provider gender',
      analyticsId: 57,
      category: 'select1',
      progressCurrent: 3,
      response_category_id: 3,
      progressMax: 10,
      heading1: null,
      heading2: 'Would you prefer a provider that is...',
      heading3: 'Please answer a few quick questions to narrow down your match.',
      buttons: [
        {
          text: 'Male',
          externalTarget: null,
          internalTarget: 6,
          answerValue: 1,
        },
        {
          text: 'Female',
          externalTarget: null,
          internalTarget: 6,
          answerValue: 2,
        },
        {
          text: "I'm not sure yet",
          externalTarget: null,
          internalTarget: 6,
          answerValue: undefined,
        },
      ],
      selfServe: {
        field: 'therapistGender',
      },
    },
    {
      id: 6,
      prompt: 'How familiar are you with therapy?',
      analyticsId: 58,
      category: 'select1',
      progressCurrent: 4,
      response_category_id: 7,
      progressMax: 10,
      heading2: 'Have you been to a provider before?',
      buttons: [
        {
          text: 'Yes',
          externalTarget: null,
          internalTarget: 7,
          answerValue: 'Yes',
        },
        {
          text: 'No',
          externalTarget: null,
          internalTarget: 7,
          answerValue: 'No',
        },
      ],
    },
    {
      id: 7,
      prompt: 'How would you rate your sleeping habits?',
      analyticsId: 59,
      category: 'select1',
      response_category_id: 7,
      progressCurrent: 5,
      progressMax: 10,
      heading2: 'How would you rate your sleeping habits?',
      buttons: [
        {
          text: 'Excellent',
          externalTarget: null,
          internalTarget: 8,
          answerValue: 'Excellent',
        },
        {
          text: 'Good',
          externalTarget: null,
          internalTarget: 8,
          answerValue: 'Good',
        },
        {
          text: 'Fair',
          externalTarget: null,
          internalTarget: 8,
          answerValue: 'Fair',
        },
        {
          text: 'Poor',
          externalTarget: null,
          internalTarget: 8,
          answerValue: 'Poor',
        },
      ],
    },
    {
      id: 8,
      prompt: 'How would you rate your physical health?',
      analyticsId: 60,
      category: 'select1',
      progressCurrent: 6,
      response_category_id: 7,
      progressMax: 10,
      heading2: 'How would you rate your current physical health?',
      buttons: [
        {
          text: 'Excellent',
          externalTarget: null,
          internalTarget: 9,
          answerValue: 'Excellent',
        },
        {
          text: 'Good',
          externalTarget: null,
          internalTarget: 9,
          answerValue: 'Good',
        },
        {
          text: 'Fair',
          externalTarget: null,
          internalTarget: 9,
          answerValue: 'Fair',
        },
        {
          text: 'Poor',
          externalTarget: null,
          internalTarget: 9,
          answerValue: 'Poor',
        },
      ],
    },
    {
      id: 9,
      prompt: 'My gender is',
      analyticsId: 62,
      category: 'select1',
      progressCurrent: 7,
      response_category_id: 4,
      progressMax: 10,
      heading2: 'Please select your gender.',
      buttons: [
        {
          text: 'Male',
          externalTarget: null,
          internalTarget: 10,
          answerValue: 1,
        },
        {
          text: 'Female',
          externalTarget: null,
          internalTarget: 10,
          answerValue: 2,
        },
        {
          text: 'Transgender male',
          externalTarget: null,
          internalTarget: 10,
          answerValue: 3,
        },
        {
          text: 'Transgender female',
          externalTarget: null,
          internalTarget: 10,
          answerValue: 4,
        },
        {
          text: 'Gender queer',
          externalTarget: null,
          internalTarget: 10,
          answerValue: 5,
        },
        {
          text: 'Gender variant',
          externalTarget: null,
          internalTarget: 10,
          answerValue: 6,
        },
        {
          text: 'Other',
          externalTarget: null,
          internalTarget: 10,
          answerValue: 7,
        },
        {
          text: 'Non-binary',
          externalTarget: null,
          internalTarget: 10,
          answerValue: 8,
        },
      ],
      skipText: 'Skip',
      skipExternalTarget: null,
      skipInternalTarget: 10,
      nextQuestionActions: [skipToStepIfClientAgeIsTeen(16), skipToStepIfClientAgeExists(11)],
    },
    {
      id: 10,
      prompt: 'What is your age?',
      analyticsId: 61,
      category: 'dateOfBirth',
      response_category_id: 5,
      progressCurrent: 8,
      progressMax: 10,
      heading2: 'Great! To improve your match, please tell us your age.',
      targets: {
        under13: 'https://helpnow.talkspace.com/under-13',
        teen: 16, // Teen not supported in this flow, this is useless right now
        adult: 11,
      },
    },
    {
      id: 16,
      // Teen not supported in this flow, this is useless right now
      prompt: 'Consent',
      analyticsId: 562,
      category: 'select1',
      heading2:
        'Based on your age and state, parent or guardian consent may be needed. Does this work for you?',
      buttons: [
        {
          text: 'Yes, I understand',
          answerValue: 'Yes, I understand',
          externalTarget: null,
          internalTarget: 11,
        },
        {
          text: 'No, I need more information',
          answerValue: 'No, I need more information',
          externalTarget: 'https://helpnow.talkspace.com/under-18-no-consent',
          internalTarget: null,
        },
      ],
      progressCurrent: 8,
      progressMax: 10,
    },
    {
      id: 11,
      prompt: 'What is your state?',
      analyticsId: 63,
      category: 'dropdown',
      progressCurrent: 9,
      doNotAllowSearch: false,
      response_category_id: 6,
      progressMax: 10,
      heading2: 'Please select your state of residence.',
      selectPlaceholder: 'Select a state',
      selectOptions: states,
      buttonText: 'Submit',
      buttonTarget: 12,
      skipExternalTarget: null,
      skipInternalTarget: 12,
    },
    {
      id: 12,
      prompt: 'Loading',
      category: 'loading1',
      buttonTarget: 13,
      hideBackButton: true,
    },
    {
      id: 13,
      prompt: 'Matches',
      category: 'matches1',
      buttonTarget: 20,
      zeroMatchTarget: 31,
      nextQuestionActions: {
        31: [skipReviewPlanIfIrrelevant],
      },
    },
    {
      id: 20,
      category: 'matchConfirmation',
      buttonTarget: 21,
    },
    {
      id: 30,
      category: 'sessionModality',
      buttonTarget: 19,
    },
    {
      id: 31,
      category: 'reviewPlan',
      buttonTarget: 30,
      nextQuestionActions: [skipModalitySelectionIfIrrelevant],
    },
    {
      id: 19,
      category: 'registerWithVoucher1',
      hideBackButton: true,
    },
    {
      id: 21,
      prompt: 'Scheduler',
      category: 'scheduler',
      buttonTarget: 31,
      nextQuestionActions: [skipReviewPlanIfIrrelevant],
    },
  ],
};

export default flow;
