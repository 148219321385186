/** @jsxRuntime classic */
/** @jsx jsx */
import 'babel-polyfill';
import configureAWSAmplify from '@talkspace/auth/configureAWSAmplify';
import { jsx } from '@emotion/core';
import ReactDOM from 'react-dom';
import { initReactI18next, i18nInit, STATIC_PATH as I18N_STATIC_PATH } from '@talkspace/i18n';
import Application from '@/Components/Application';
import apiWrapper from '@/core/api/apiWrapper';
import * as serviceWorker from './registerServiceWorker';
import 'typeface-roboto';
import 'typeface-roboto-condensed';
import './Assets/fonts/quarto/quarto-font.css';
import { initTracker } from './utils/analytics/eventTracker';

configureAWSAmplify();
initTracker(apiWrapper);
i18nInit(initReactI18next, I18N_STATIC_PATH);

ReactDOM.render(<Application />, document.getElementById('root'));
serviceWorker.unregister();
