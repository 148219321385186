import TSConfigs from '@talkspace/configs';
import { AccountType, HomePageState } from '../Components/HomePage/types';
import { states } from '../Helpers/locales';
import { shouldSkipOrRemovePreferredModality } from '../Helpers/preferredModalityHelper';
import { getTherapistAbilityPreferences } from '../Helpers/therapistHelper';
import getParamByName from '../utils/queryString';
// This import in particular needs user interaction, therefore, "safe" to be a cycle
// eslint-disable-next-line import/no-cycle
import { getClientFlow, getClientFlowConfig } from './index';
// eslint-disable-next-line import/no-cycle
import { FLOW_138_B2B_VOUCHER_GENERIC_V2 } from './Flow138';
// eslint-disable-next-line import/no-cycle
import { FLOW_7_B2B_COPAY_GENERIC } from './Flow7';
import { EligibilityType, OneFormEligibilityStep, QMFlow, UpdateStepObj } from './types';
import {
  allRegistrationPagesSteps,
  ineligiblePromo,
  initFlowConfig,
  registrationStepsValidation,
  skipIfExperiment,
  skipToStepIfDoesntMeetAvailabilityPreferencesRequirements,
} from './utils';
import { QM_COPY } from '../utils/qmCopy';

const OFFER_ID = 103;
const INITIAL_OFFER_STEP_ID = 23;
const MATCHES_STEP_ID = 17;

const getRegistrationStepIDByFlowID = (state: HomePageState, answer?: UpdateStepObj): number => {
  const { organizationFlowID, partnerFlowID } = state;
  let { qmPartnerCode, cpPartnerCode } = state;
  let accessCodeType = getParamByName('accessCodeType') as AccessCodeType | null;

  if (
    !(accessCodeType || qmPartnerCode || cpPartnerCode) &&
    (answer?.accessCodeType || answer?.accessCode)
  ) {
    accessCodeType = answer.accessCodeType || null;
    // Attempt to find the cpPartnerCode first
    if (accessCodeType && accessCodeType === 'cpPartnerCode') {
      cpPartnerCode = answer.accessCode || '';
    }
    // Default to qmPartnerCode
    if (!cpPartnerCode) {
      qmPartnerCode = answer.accessCode || '';
    }
  }
  const flowID = (organizationFlowID || partnerFlowID) as number;
  let flow = flowID && getClientFlow(flowID);
  if (cpPartnerCode || accessCodeType === 'cpPartnerCode') {
    flow = getClientFlow(FLOW_7_B2B_COPAY_GENERIC);
  }
  if (qmPartnerCode || accessCodeType === 'qmPartnerCode') {
    flow = getClientFlow(FLOW_138_B2B_VOUCHER_GENERIC_V2);
  }
  if (flow) {
    const registrationStepIndexes = flow.steps
      .map((step) => registrationStepsValidation.findIndex((validationFun) => validationFun(step)))
      .filter((index) => index > -1);

    if (registrationStepIndexes.length > 0) {
      return INITIAL_OFFER_STEP_ID + registrationStepIndexes[0];
    }
  }
  return INITIAL_OFFER_STEP_ID;
};

const skipEligibilityForDTE = (_state: HomePageState, answer?: UpdateStepObj) => {
  const { organizationFlowID } = answer || {};
  // If we successfully received a qmFlowID and the target flow has an eligibility type, show form
  if (
    organizationFlowID &&
    getClientFlowConfig(organizationFlowID)?.eligibilityType !== undefined
  ) {
    return undefined;
  }
  return MATCHES_STEP_ID; // Matches step
};

const skipModalitySelectionIfIrrelevant =
  (skipToStepID?: number) => (state: HomePageState, answer?: UpdateStepObj) => {
    const allowedModalities = answer?.allowedModalities || state.allowedModalities || undefined;
    if (shouldSkipOrRemovePreferredModality({ ...state, allowedModalities })) {
      return skipToStepID || getRegistrationStepIDByFlowID(state, answer); // skip to registration if no modalities found.
    }
    return undefined;
  };

const skipReviewPlanIfIrrelevant =
  (skipToStepID?: number) => (state: HomePageState, answer?: UpdateStepObj) => {
    const accountType = answer?.accountType || state.accountType;
    const manualFlowID = answer?.manualFlowID || state.manualFlowID;

    const manualFlowConfig = manualFlowID ? getClientFlowConfig(manualFlowID) : undefined;
    const isManualFlow =
      manualFlowConfig?.eligibilityType === EligibilityType.manual ||
      getClientFlowConfig(state.organizationFlowID || state.partnerFlowID || state.redirectFrom)
        ?.eligibilityType === EligibilityType.manual;
    if (accountType && [AccountType.EAP, AccountType.BH].includes(accountType) && !isManualFlow) {
      return undefined;
    }
    return skipModalitySelectionIfIrrelevant(skipToStepID)(state, answer) || 30; // go to modalities
  };

const skipReviewPlanIfOrganization = (state: HomePageState, answer?: UpdateStepObj) => {
  if (state.organizationFlowID) return getRegistrationStepIDByFlowID(state, answer);
  return undefined;
};

// Omitting category for readability
const ONE_FORM_ELIGIBILITY_DEFAULT_STEP_CONFIG: Omit<OneFormEligibilityStep, 'id' | 'category'> = {
  analyticsId: 54,
  response_category_id: 9,
  // nextQuestionActions: [goToMatchesOrRegistration],
  ...ineligiblePromo,
};

/** Service Specific Funnels: Couples Therapy */
export const FLOW_101_ELIGIBILITY_QUESTIONS_COUPLES_THERAPY = 101;

const getCouplesTherapyTreatmentGoals = () => {
  const configKey = 'couplesTherapyTreatmentGoals';
  return Object.keys(TSConfigs[configKey] ? TSConfigs[configKey] : {}).map((key) => {
    return {
      value: key,
      label: TSConfigs[configKey][key],
    };
  });
};

const couplesTherapyTreatmentGoals = getCouplesTherapyTreatmentGoals();
const therapistAbilityPreferences = getTherapistAbilityPreferences();

const flow: QMFlow = {
  flowId: FLOW_101_ELIGIBILITY_QUESTIONS_COUPLES_THERAPY,
  flowConfig: {
    ...initFlowConfig,
    serviceType: 'therapyCouples',
    isB2B: false,
    shouldHideServiceSelection: true,
    version: 1,
  },
  steps: [
    {
      id: 1,
      prompt: 'What led you to seek help?',
      analyticsId: 54,
      response_category_id: 2,
      category: 'multiselect',
      multiselect: couplesTherapyTreatmentGoals,
      heading1: null,
      heading2: 'To begin, please select why you thought about getting help from a provider.',
      externalTarget: null,
      internalTarget: 135,
      progressCurrent: 1,
      progressMax: 13,
      multiSelectLabelText: 'Select all that apply',
      submitText: 'Continue',
    },
    {
      id: 135,
      category: 'memberAvailability',
      prompt: 'When are you generally available for therapy?',
      heading2: 'When are you generally available for therapy?',
      skipQuestionActions: [
        skipToStepIfDoesntMeetAvailabilityPreferencesRequirements(2),
        skipIfExperiment({
          experimentName: 'clientAvailabilityPreferencesExperiment',
          variant: 'control',
          nextStepId: 2,
          shouldTrack: true,
        }),
      ],
      buttonTarget: 2,
    },
    {
      id: 2,
      prompt: 'I am looking for a provider that will: ',
      analyticsId: 87,
      response_category_id: 7,
      category: 'dropdown',
      heading2: "I'm looking for a provider that will...",
      selectPlaceholder: 'Please select one option',
      selectOptions: therapistAbilityPreferences,
      buttonText: 'Continue',
      buttonTarget: 3,
      internalTarget: 3,
      externalTarget: null,
      progressCurrent: 2,
      progressMax: 13,
      selectLabelText: 'Treatment approach',
    },
    {
      id: 3,
      prompt: 'Have you and your partner worked with a couples counselor before?',
      analyticsId: 88,
      response_category_id: 7,
      category: 'select1',
      heading2: 'Have you and your partner worked with a couples counselor before?',
      buttons: [
        {
          text: 'Yes',
          externalTarget: null,
          internalTarget: 4,
          answerValue: 'Yes',
        },
        {
          text: 'No',
          externalTarget: null,
          internalTarget: 4,
          answerValue: 'No',
        },
      ],
      progressCurrent: 3,
      progressMax: 13,
    },
    {
      id: 4,
      prompt: 'Do you currently live with your partner?',
      analyticsId: 89,
      response_category_id: 7,
      category: 'select1',
      heading2: 'Do you currently live with your partner?',
      buttons: [
        {
          text: 'Yes',
          externalTarget: null,
          internalTarget: 5,
          answerValue: 'Yes',
        },
        {
          text: 'No',
          externalTarget: null,
          internalTarget: 5,
          answerValue: 'No',
        },
      ],
      progressCurrent: 4,
      progressMax: 13,
    },
    {
      id: 5,
      prompt: 'What type of relationship do you have?',
      analyticsId: 90,
      response_category_id: 7,
      category: 'select1',
      heading2: 'What type of relationship do you have?',
      buttons: [
        {
          text: 'Straight',
          externalTarget: null,
          internalTarget: 6,
          answerValue: 'Straight',
        },
        {
          text: 'Gay/Lesbian',
          externalTarget: null,
          internalTarget: 6,
          answerValue: 'Gay/Lesbian',
        },
        {
          text: 'Other',
          externalTarget: null,
          internalTarget: 6,
          answerValue: 'Other',
        },
      ],
      progressCurrent: 5,
      progressMax: 13,
    },
    {
      id: 6,
      prompt: 'Is domestic violence currently an issue in your relationship?',
      analyticsId: 91,
      response_category_id: 7,
      category: 'select1',
      heading2: 'Is domestic violence currently an issue in your relationship?',
      buttons: [
        {
          text: 'Yes',
          externalTarget: null,
          internalTarget: 7,
          answerValue: 'Yes',
        },
        {
          text: 'No',
          externalTarget: null,
          internalTarget: 7,
          answerValue: 'No',
        },
        {
          text: 'Sometimes',
          externalTarget: null,
          internalTarget: 7,
          answerValue: 'Sometimes',
        },
      ],
      progressCurrent: 6,
      progressMax: 13,
    },
    {
      id: 7,
      prompt: 'When would you be looking to get started with a counselor?',
      analyticsId: 92,
      response_category_id: 7,
      category: 'select1',
      heading2: 'When would you be looking to get started with a counselor?',
      buttons: [
        {
          text: "We're ready now",
          externalTarget: null,
          internalTarget: 11,
          answerValue: "We're ready now",
        },
        {
          text: 'In the next couple of months',
          externalTarget: null,
          internalTarget: 11,
          answerValue: 'In the next couple of months',
        },
        {
          text: 'Not sure yet',
          externalTarget: null,
          internalTarget: 11,
          answerValue: undefined,
        },
      ],
      progressCurrent: 7,
      progressMax: 13,
    },
    // matching questions
    {
      id: 11,
      prompt: 'My gender is',
      analyticsId: 62,
      response_category_id: 4,
      category: 'select1',
      heading2: 'What gender do you identify with?',
      buttons: [
        {
          text: 'Male',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 1,
        },
        {
          text: 'Female',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 2,
        },
        {
          text: 'Transgender male',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 3,
        },
        {
          text: 'Transgender female',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 4,
        },
        {
          text: 'Gender queer',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 5,
        },
        {
          text: 'Gender variant',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 6,
        },
        {
          text: 'Other',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 7,
        },
        {
          text: 'Non-binary',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 8,
        },
      ],
      skipText: 'Skip',
      skipExternalTarget: null,
      skipInternalTarget: 12,
      progressCurrent: 8,
      progressMax: 13,
    },
    {
      id: 12,
      prompt: 'Preferred provider gender',
      analyticsId: 57,
      response_category_id: 3,
      category: 'select1',
      heading2: 'What gender would you prefer in a provider?',
      selfServe: {
        field: 'therapistGender',
      },
      buttons: [
        {
          text: 'Male',
          externalTarget: null,
          internalTarget: 13,
          answerValue: 1,
        },
        {
          text: 'Female',
          externalTarget: null,
          internalTarget: 13,
          answerValue: 2,
        },
        {
          text: "I'm not sure yet",
          externalTarget: null,
          internalTarget: 13,
          answerValue: undefined,
        },
      ],
      progressCurrent: 9,
      progressMax: 13,
    },
    {
      id: 13,
      prompt: 'What is your age?',
      analyticsId: 61,
      response_category_id: 5,
      category: 'dateOfBirth',
      heading2: "What's your date of birth?",
      targets: {
        under13: 'https://helpnow.talkspace.com/under-13',
        teen: 23,
        adult: 14,
      },
      progressCurrent: 10,
      progressMax: 13,
    },
    {
      id: 14,
      prompt: QM_COPY.state.prompt,
      analyticsId: 63,
      response_category_id: 6,
      category: 'dropdown',
      heading2: QM_COPY.state.title,
      heading3: QM_COPY.state.subtitle,
      selectPlaceholder: QM_COPY.state.placeholder,
      selectOptions: states,
      buttonText: QM_COPY.state.continue,
      buttonTarget: 15,
      skipExternalTarget: null,
      progressCurrent: 11,
      progressMax: 13,
    },
    {
      id: 15,
      category: 'dispatcherInQM',
      initialDispatcherStep: 'organizationName',
      internalTarget: 16,
      progressCurrent: 12,
      progressMax: 13,
      nextQuestionActions: [skipEligibilityForDTE],
    },
    {
      id: 16,
      category: 'oneFormEligibility',
      progressCurrent: 13,
      progressMax: 13,
      buttonTarget: 32,
      nextQuestionActions: [skipReviewPlanIfIrrelevant(MATCHES_STEP_ID)],

      ...ONE_FORM_ELIGIBILITY_DEFAULT_STEP_CONFIG,
    },
    {
      id: 30,
      category: 'sessionModality',
      buttonTarget: MATCHES_STEP_ID,
    },
    {
      id: MATCHES_STEP_ID,
      prompt: 'Matches',
      category: 'matches1',
      buttonTarget: 18,
      zeroMatchTarget: 18,
      matchOverlay: true,
      canMatchOverlayBeSkipped: true,
      showCustomerRegistrationModalWithoutPayment: true,
    },
    {
      id: 18,
      category: 'oneFormEligibility',
      noCoverageButtonTarget: INITIAL_OFFER_STEP_ID,
      buttonTarget: 33,
      nextQuestionActions: [skipReviewPlanIfOrganization, skipReviewPlanIfIrrelevant()],
      disableOrganization: true,
      ...ONE_FORM_ELIGIBILITY_DEFAULT_STEP_CONFIG,
    },
    {
      id: 31,
      category: 'sessionModality',
      buttonTarget: INITIAL_OFFER_STEP_ID,
      nextQuestionActions: [getRegistrationStepIDByFlowID],
    },
    {
      id: 32,
      category: 'reviewPlan',
      buttonTarget: 30,
      nextQuestionActions: [skipModalitySelectionIfIrrelevant(MATCHES_STEP_ID)],
    },
    {
      id: 33,
      category: 'reviewPlan',
      buttonTarget: 31,
      nextQuestionActions: [skipModalitySelectionIfIrrelevant()],
    },
    ...allRegistrationPagesSteps({
      initialStepID: INITIAL_OFFER_STEP_ID,
      offersPageSettings: {
        offerID: OFFER_ID,
        previousStepsUntilMatches: 2,
      },
    }),
  ],
};

export default flow;
