/* eslint-disable import/prefer-default-export */
import {
  useEmotionTheme,
  Large,
  Small,
  View,
  Modal,
  Big,
  ComplianceBadges,
  PlanDetails,
  X,
  TouchableView,
  useWindowWidth,
  useUniqueID,
} from '@talkspace/react-toolkit';
import styled from '@/core/styled';

const Styled = {
  ModalBigText: styled(Big)(
    ({
      theme: {
        window: { isMobile },
      },
    }) => {
      if (isMobile) {
        return {
          alignSelf: 'center',
          width: 320,
          paddingLeft: 115,
        };
      }
      return {
        fontSize: 29,
        alignSelf: 'center',
      };
    }
  ),
  HorizontalLine: styled(View)(({ theme: { colors } }) => {
    return {
      width: '100%',
      marginTop: 15,
      borderTop: `1px solid ${colors.permaMischkaNew}`,
    };
  }),
  SmallTextWrapper: styled(Small)<{ textColor: string }>(({ textColor }) => {
    return {
      color: textColor,
      marginTop: 16,
      textAlign: 'left',
    };
  }),
};

export const CoveredDetailsModal = ({
  hideDetailsModal,
  isVisible,
  modalDetails,
}: {
  hideDetailsModal: () => void;
  isVisible: boolean;
  modalDetails;
}) => {
  const titleId = useUniqueID('titleId');
  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidth();

  return (
    <Modal
      onBackdropPress={hideDetailsModal}
      titleText="Plan Details"
      titleID={titleId}
      isVisible={isVisible}
    >
      <Modal.Panel
        contentViewStyle={{
          ...(!isMobile && {
            paddingLeft: 15,
            paddingRight: 15,
            paddingBottom: 0,
          }),
        }}
        showCloseButton={false}
        onBackdropPress={hideDetailsModal}
      >
        {!isMobile && (
          <View style={{ alignSelf: 'center' }}>
            <PlanDetails />
          </View>
        )}
        <View style={{ flexDirection: 'row', alignItems: 'center', alignSelf: 'center' }}>
          <Styled.ModalBigText>Plan Details</Styled.ModalBigText>
          {isMobile && (
            <TouchableView onPress={hideDetailsModal}>
              <X />
            </TouchableView>
          )}
        </View>

        <Styled.HorizontalLine />
        <Styled.SmallTextWrapper textColor={colors.permaFiord} variant="smallBoldGrey">
          {modalDetails.topText}
        </Styled.SmallTextWrapper>
        <>
          {modalDetails.paragraphs.map((paragraph, i) => (
            <View>
              {/* i == 0 will yield true for first paragraph to style it differently. */}
              {i === 0 && (
                <Big style={{ marginTop: 6, color: colors.permaOuterSpaceNew, fontWeight: 400 }}>
                  {paragraph.title}
                </Big>
              )}
              {i !== 0 && (
                <Styled.SmallTextWrapper textColor={colors.permaFiord} variant="smallBoldGrey">
                  {paragraph.title}
                </Styled.SmallTextWrapper>
              )}
              {i === 0 && (
                <Large style={{ marginTop: 6, color: colors.permaFiord }}>
                  {paragraph.description}
                </Large>
              )}
              {i !== 0 && (
                <Styled.SmallTextWrapper textColor={colors.permaFiord}>
                  {paragraph.description}
                </Styled.SmallTextWrapper>
              )}
              <Styled.HorizontalLine style={{ marginTop: 20 }} />
            </View>
          ))}
        </>
        <ComplianceBadges style={{ alignSelf: 'center', marginTop: 13, marginBottom: 26 }} />
      </Modal.Panel>
    </Modal>
  );
};
