import { withPromiseMessageContext } from 'ts-promise-message';
import { withFlagsProvider } from 'launchDarkly/FlagsProvider';
import compose from 'lodash/fp/compose';
import withAccessibility from './withAccessibility';
import withWindowWidth from './withWindowWidth';
import withView from './withView';
import withEmotion from './withEmotion';
import withReactFrameService from './withFrameService';
import withRouter from './withRouter';
import withLaunchDarkly from './withLaunchDarkly';
import withTranslation from './withTranslation';
import { withRecoveredSession } from '@/hooks/recoveredSessionContext';
import { withGoogleMapsContext } from '@/hooks/googleMapsContext';
import withQueryClient from './withQueryClient';
import withAppLogs from './withAppLogs';
import withCypressHooks from './withCypressHooks';
import './styles.css';

const Setup = compose(
  withAccessibility,
  withView,
  withWindowWidth,
  withEmotion,
  withReactFrameService,
  withRouter,
  withRecoveredSession,
  /** withRecoveredSession does apiWrapper.initialize(), required by withLaunchDarkly's QM version */
  withLaunchDarkly,
  withPromiseMessageContext,
  withGoogleMapsContext,
  withQueryClient,
  withFlagsProvider,
  withAppLogs,
  withCypressHooks,
  withTranslation
)(({ children }) => children);

export default Setup;
