/* eslint-disable import/prefer-default-export */
import { useCallback } from 'react';
import moment from 'moment';
import { trackEvent } from '@/utils/analytics/eventTracker';
import { DiscoveryRequest, submitDiscoveryRequest } from './DiscoveryRequest';
import { UseOnSubmitDiscoveryOptions } from './types';
import { getInsuranceVerificationFlowID } from '@/Helpers/insurancePayersHelper';
import getTransactionStatus from './DiscoveryStatusRequest';

export const useOnSubmit = ({
  state,
  setState,
  service,
  insurancePayer,
  maxAttempts,
  discoveryConfiguration,
}: UseOnSubmitDiscoveryOptions) =>
  useCallback(
    async (data) => {
      if (state.result || state.isSubmitting || state.attempts >= maxAttempts) {
        return;
      }

      setState((_state) => {
        return {
          ..._state,
          attempts: _state.attempts + 1,
          isSubmitting: true,
          formattedError: null,
          error: null,
          errorType: null,
        };
      });

      const { flowID: overrideFlowID } = await getInsuranceVerificationFlowID(
        insurancePayer,
        service
      );

      if (!overrideFlowID) {
        return;
      }

      try {
        const transactionResult = await submitDiscoveryRequest({
          firstName: data.firstName,
          lastName: data.lastName,
          dateOfBirth: moment(data.dob, 'MM/DD/YYYY').format('YYYY-MM-DD'),
          state: data.state,
          gender: data.gender,
          gediPayerID: data.gediPayerID,
          service,
        });

        trackEvent('Submit Discovery Request', {
          insuranceProvider: insurancePayer?.label || '',
          isDiscoveryScreen: true,
        });

        if (transactionResult.error) {
          throw new Error('408'); // simulate timeout error to enter "Enter Member ID" screen
        }

        const { firstDelayInSeconds, delayInSeconds, maxRequestsCount } = discoveryConfiguration;
        const firstDelayMs = firstDelayInSeconds * 1000;
        const otherDelayMs = delayInSeconds * 1000;
        const maxRetries = maxRequestsCount;

        const apiResult = await getTransactionStatus({
          externalEligibilityRequestID: transactionResult.externalEligibilityRequestID,
          flowID: overrideFlowID,
          firstDelayMs,
          otherDelayMs,
          maxAttempts: maxRetries,
        });

        trackEvent('Submit Coverage Information', {
          insuranceProvider: insurancePayer?.label || '',
          outcome: apiResult.isEligible ? 'Eligible' : 'Not Eligible',
          copayAmount: (apiResult.copayCents || 0) / 100,
          isDiscoveryScreen: true,
        });

        setState((_state) => {
          return {
            ..._state,
            result: apiResult,
            canRetry: false,
            isSubmitting: false,
            error: null,
            formattedError: null,
            errorType: null,
            done: true,
          };
        });
      } catch (err) {
        const isNonTimeoutError = err?.response?.status >= 400;
        const isDataDomeResponse = err?.response?.headers && 'x-dd-b' in err.response.headers;
        const done =
          // +1 because we updated state.attempts earlier in the function
          state.attempts + 1 >= maxAttempts ||
          // Disable retry for non-timeout errors
          // Always retry if datadome response
          (isNonTimeoutError && !isDataDomeResponse);
        // Only send the event once, at the end of the retries
        if (done) {
          // This event is specific to the coverage verification experiment. Be sure to change
          // if you're using this hook somewhere else.
          if (isNonTimeoutError) {
            trackEvent('Submit Coverage Information', {
              insuranceProvider: insurancePayer?.label || '',
              outcome: 'Verification Failed',
              isDiscoveryScreen: true,
            });
          } else {
            trackEvent('Submit Coverage Information', {
              insuranceProvider: insurancePayer?.label || '',
              outcome: 'Eligible with manual service keyword',
              isDiscoveryScreen: true,
            });
          }
        }

        const errorType = DiscoveryRequest.getErrorType(err);

        setState((_state) => {
          return {
            ..._state,
            canRetry: _state.attempts < maxAttempts,
            done,
            result: null,
            isSubmitting: false,
            error: err,
            errorType,
          };
        });
      }
    },
    [
      state.result,
      state.isSubmitting,
      state.attempts,
      maxAttempts,
      setState,
      insurancePayer,
      service,
      discoveryConfiguration,
    ]
  );
