import { isZipValid } from '@talkspace/react-toolkit';
import * as yup from 'yup';
import { countryTestFunction } from '../../OneFormEligibility/util';
import { ageErrorCopy, isUnderage, isValidDate } from '../../../Helpers/ageHelper';

export interface TeenZipcodeEligibilityFields {
  dateOfBirth: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  zipcode: string;
  country: string;
  clientState: string;
}

export interface TeenZipcodeEligibilityYupContext {
  flowId?: number;
}

export const DEFAULT_ERROR = 'Please enter or select a different address';

const teenZipcodeEligibilitySchema = yup.object().shape({
  dateOfBirth: yup
    .string()
    .test(
      'is-under-age',
      (userInput?: string, testContext?: yup.TestContext<TeenZipcodeEligibilityYupContext>) => {
        const isUnderAge = isUnderage(userInput, testContext?.options?.context?.flowId);

        if (testContext && userInput && isUnderAge) {
          return testContext.createError({
            message: ageErrorCopy(testContext.options?.context?.flowId),
          });
        }

        return true;
      }
    )
    .test(
      'is-valid-date-format',
      'This is not a valid date',
      (userInput?: string, testContext?: yup.TestContext<TeenZipcodeEligibilityYupContext>) =>
        userInput
          ? isValidDate(userInput, testContext?.options?.context?.flowId, 'MM/DD/YYYY')
          : true
    )
    .required('Date of birth is required'),
  addressLine1: yup.string().test({
    name: 'address-line-test',
    test: async (addressLine1Value, context) => {
      const errorMessage = 'Address is missing or invalid.';
      const schema = yup.string().required();
      return (
        (await schema.isValid(addressLine1Value)) ||
        context.createError({
          message: errorMessage,
        })
      );
    },
  }),
  // never required
  addressLine2: yup.string().optional(),
  city: yup.string().required(DEFAULT_ERROR),
  zipcode: yup
    .string()
    .test('is-required-zip-code', DEFAULT_ERROR, (zipCodeValue, context) => {
      if (zipCodeValue && zipCodeValue.length > 0) {
        return true;
      }
      return false;
    })
    .test(
      'is-valid-zip-code',
      DEFAULT_ERROR,
      (zipCodeValue, context) =>
        zipCodeValue && context.parent.country && isZipValid(zipCodeValue, context.parent.country)
    ),
  country: yup
    .string()
    .test(
      'is-valid-country',
      'Services are not covered for selected country',
      (userInput?: string) => countryTestFunction(userInput)
    ),
  clientState: yup
    .string()
    .required(DEFAULT_ERROR)
    .test('is-required-state', DEFAULT_ERROR, (userInput: string) => {
      if (userInput && userInput.length > 0) {
        return true;
      }
      return false;
    })
    .test('is-valid-state', DEFAULT_ERROR, (userInput: string, testContext) => {
      const hasSelectedUS = testContext.parent.country === 'US';
      const clientStateInput = testContext.parent.clientState;
      const stateRegex = /^[a-zA-Z]{2}$/g;
      if (hasSelectedUS) {
        if (userInput && userInput.length > 0) {
          return stateRegex.test(userInput);
        }
        if (clientStateInput.length > 0) {
          return stateRegex.test(clientStateInput);
        }
      }
      return true;
    }),
});

export default teenZipcodeEligibilitySchema;
