/* eslint-disable import/prefer-default-export */
import { useEffect, useRef } from 'react';
import { InsuranceEligibilityPayer } from 'ts-frontend/types';
import { QuickEligibilityStep, UpdateStep } from '@/Flows';
import {
  DiscoveryStatusResponse,
  DiscoveryServiceFormData,
  UseEligibilityServiceErrorType,
} from '@/hooks';

type UseContinueParams = {
  result: DiscoveryStatusResponse | null;
  data: DiscoveryServiceFormData;
  insurancePayer: InsuranceEligibilityPayer | undefined;
  updateStep: UpdateStep;
  step: QuickEligibilityStep;
  done: boolean;
  errorType: UseEligibilityServiceErrorType | null;
};

export const useDiscoveryContinue = ({
  result,
  data,
  insurancePayer,
  updateStep,
  step,
  done,
  errorType,
}: UseContinueParams) => {
  const processedRef = useRef({
    hasProcessed: false,
  });

  useEffect(() => {
    if (processedRef.current.hasProcessed) {
      return undefined;
    }
    if (!done) {
      return undefined;
    }
    processedRef.current.hasProcessed = true;
    if (!errorType && result) {
      const insuranceEligibility = {
        ...result,
        verificationSucceeded: true,
      };
      const newState = {
        quickEligibilityInfo: { ...data, insurancePayer, insuranceEligibility },
        insuranceEligibility,
      };
      updateStep(step.inNetworkTarget, newState);
      return undefined;
    }
    const insuranceEligibility = {
      isEligible: false,
      verificationSucceeded: false,
      isVideoOnlyPlan: false,
    };
    const newState = {
      quickEligibilityInfo: {
        firstName: data.firstName,
        lastName: data.lastName,
        dob: data.dob,
        gender: data.gender,
        payerID: insurancePayer?.id || 0,
        state: data.state,
        country: data.country,
        insurancePayer,
        insuranceEligibility,
      },
      insuranceEligibility,
    };
    switch (errorType) {
      case 'TIMEOUT':
        updateStep(step.enterMemberIDTarget, newState);
        break;
      case 'VERIFICATION_FAILURE':
      default:
        // for non-timeout errors, continue to no insurance screen
        updateStep(step.inNetworkTarget, newState);
    }
    return undefined;
  }, [result, data, updateStep, step, done, errorType, insurancePayer]);
};
