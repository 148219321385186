import { createContext, useState, useEffect, useContext } from 'react';
import { View, Spinner } from '@talkspace/react-toolkit';
import googleMapSetup from '../utils/analytics/tools/googleMapSetup';

interface GoogleMapsState {
  status: boolean;
}

const GoogleMapsContext = createContext<GoogleMapsState | undefined>(undefined);

export function GoogleMapsContextProvider({ children }) {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    googleMapSetup.initSDK((success: boolean) => {
      if (!success) {
        // eslint-disable-next-line no-console
        console.warn('Could not setup Google Map library');
      }
      setIsReady(true);
    });
  }, []);

  if (!isReady)
    return (
      <View style={{ height: '100vh' }}>
        <View flex={1}>
          <Spinner isLoading />
        </View>
      </View>
    );

  return (
    <GoogleMapsContext.Provider value={{ status: isReady }}>{children}</GoogleMapsContext.Provider>
  );
}

export const withGoogleMapsContext = (Component) => (props) =>
  (
    <GoogleMapsContextProvider>
      <Component {...props} />
    </GoogleMapsContextProvider>
  );

export function useGoogleMapsState() {
  const context = useContext(GoogleMapsContext);
  if (context === undefined)
    throw new Error('GoogleMapsContext must be used within GoogleMapsContextProvider');

  return context;
}
