import { Dispatch, SetStateAction, useEffect } from 'react';
import { SignUpData } from '@talkspace/react-toolkit';
import register from '@talkspace/auth/register';
import formatPhoneNumber from 'ts-frontend/utils/phoneNumbers';
import { parsePhoneNumber } from '@talkspace/react-toolkit/src/utils';
import { trackEvent } from '../../../utils/analytics/events';
import { redirectToRoom, RegistrationErrors } from '../../../Helpers/registrationHelpers';
import {
  QuickmatchRegistrationErrorResponse,
  updateLoginCognito,
  updateLoginWithPhone,
  getRegisteredUserAccountActivationInfo,
} from '../../../Helpers/apiService';

export const onSubmit = async (
  formState: SignUpData,
  password: string,
  userID: number,
  setRequestPending: Dispatch<SetStateAction<boolean>>,
  setErrorMessage: Dispatch<SetStateAction<string>>,
  setIsError: Dispatch<SetStateAction<boolean>>,
  cognitoToken: string | null,
  updateCredentialsJWTToken: string | null,
  cognitoActive?: boolean
) => {
  setRequestPending(true);
  try {
    if (cognitoActive && (!cognitoToken || !updateCredentialsJWTToken)) {
      throw Error('No auth token pair');
    }
    if (!updateCredentialsJWTToken) {
      throw Error('No auth token');
    }
    let redirectURL;
    if (cognitoActive && cognitoToken) {
      const { redirectURL: cognitoRedirectURL } = await updateLoginCognito(
        userID,
        {
          pseudonym: formState.nickname,
          phoneNumber: formState.phoneNumber,
          phoneNumberCountryCode: formState.phoneNumberCountryCode,
          acceptSMSMarketing: formState.isChecked,
        },
        {
          headers: {
            Authorization: `Bearer ${updateCredentialsJWTToken}`,
          },
        }
      );

      // cognito specific, we don't do this with standard registration
      redirectURL = cognitoRedirectURL;
      const cognitoPhoneNumber = formState.phoneNumber
        ? formatPhoneNumber(formState.phoneNumber, formState.phoneNumberCountryCode)
        : undefined;
      await register({
        password,
        authToken: cognitoToken,
        email: formState.email as string,
        phoneNumber: cognitoPhoneNumber,
      });
    } else {
      const { redirectURL: nonCognitoRedirectURL } = await updateLoginWithPhone(
        userID,
        {
          pseudonym: formState.nickname,
          password,
          confirmedPassword: password,
          phoneNumber: formState.phoneNumber,
          phoneNumberCountryCode: formState.phoneNumberCountryCode,
          acceptSMSMarketing: formState.isChecked,
        },
        {
          headers: {
            Authorization: `Bearer ${updateCredentialsJWTToken}`,
          },
        }
      );
      redirectURL = nonCognitoRedirectURL;
    }

    if (!redirectURL) {
      throw Error('No redirect url');
    }

    trackEvent('Register', {
      'User ID': userID,
      'How did you hear about us?': formState.referralSource?.value,
    });

    setTimeout(() => redirectToRoom(redirectURL), 1000);
  } catch (err) {
    const error = err as QuickmatchRegistrationErrorResponse;
    const errMessage = error?.response?.data?.error?.message || RegistrationErrors.generalError;
    setErrorMessage(errMessage);
    setIsError(true);
  }
  setRequestPending(false);
};

const getFormValuesFromAPIResponse = async ({
  userID,
  phoneParam,
  sessionConsent,
  consentParam,
  setIsLoadingRegisteredUserInfo,
  setEmail,
  setPhoneNumber,
  setPhoneNumberCountryCode,
  setHasCountryAndStateInfo,
  setConsent,
}: {
  userID: number;
  phoneParam: string | null;
  sessionConsent: boolean;
  consentParam: string | null;
  setIsLoadingRegisteredUserInfo: Dispatch<SetStateAction<boolean>>;
  setEmail: Dispatch<SetStateAction<string>>;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  setPhoneNumberCountryCode: Dispatch<SetStateAction<string>>;
  setHasCountryAndStateInfo: Dispatch<SetStateAction<boolean>>;
  setConsent: Dispatch<SetStateAction<boolean>>;
}) => {
  setIsLoadingRegisteredUserInfo(true);
  try {
    const resp = await getRegisteredUserAccountActivationInfo(userID);
    const { email, phoneNumber, phoneNumberCountryCode, country, state } = resp;
    if (email) {
      setEmail(email);
    }
    if (phoneNumber || phoneParam) {
      setPhoneNumber(phoneNumber || phoneParam);
    }
    if (phoneNumberCountryCode) {
      setPhoneNumberCountryCode(phoneNumberCountryCode);
    }
    if (!phoneNumber && !phoneNumberCountryCode && phoneParam) {
      const { country: countryCode, nationalNumber } = parsePhoneNumber(phoneParam);
      setPhoneNumber(nationalNumber);
      setPhoneNumberCountryCode(country || countryCode);
      setHasCountryAndStateInfo(country || countryCode);
    }

    if ((state && country) || country) {
      setHasCountryAndStateInfo(true);
    }
    if (email && (phoneNumber || phoneParam) && (sessionConsent || consentParam === 'true')) {
      setConsent(true);
    }
  } catch (err) {
    throw Error('Could not retrieve registered user info');
  }
  setIsLoadingRegisteredUserInfo(false);
};

export const useValuesFromAPIResponse = ({
  userID,
  phoneParam,
  sessionConsent,
  consentParam,
  setIsLoadingRegisteredUserInfo,
  setEmail,
  setPhoneNumber,
  setPhoneNumberCountryCode,
  setHasCountryAndStateInfo,
  setConsent,
}: {
  userID: number;
  phoneParam: string | null;
  sessionConsent: boolean;
  consentParam: string | null;
  setIsLoadingRegisteredUserInfo: Dispatch<SetStateAction<boolean>>;
  setEmail: Dispatch<SetStateAction<string>>;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  setPhoneNumberCountryCode: Dispatch<SetStateAction<string>>;
  setHasCountryAndStateInfo: Dispatch<SetStateAction<boolean>>;
  setConsent: Dispatch<SetStateAction<boolean>>;
}) => {
  useEffect(() => {
    getFormValuesFromAPIResponse({
      userID,
      phoneParam,
      sessionConsent,
      consentParam,
      setIsLoadingRegisteredUserInfo,
      setEmail,
      setPhoneNumber,
      setPhoneNumberCountryCode,
      setHasCountryAndStateInfo,
      setConsent,
    });
  }, [
    userID,
    phoneParam,
    sessionConsent,
    consentParam,
    setIsLoadingRegisteredUserInfo,
    setEmail,
    setPhoneNumber,
    setPhoneNumberCountryCode,
    setHasCountryAndStateInfo,
    setConsent,
  ]);
};
