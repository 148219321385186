import { FunctionComponent, useEffect, useCallback } from 'react';
import {
  Input,
  useEmotionTheme,
  Button,
  Link,
  View,
  useUniqueID,
  useErrorFocus,
  commonStyles,
  Label,
  useObjectState,
} from '@talkspace/react-toolkit';
import moment from 'moment';
import { ServiceType } from 'ts-frontend/types';
import { Trans } from '@talkspace/i18n';
import useTranslation from '@talkspace/i18n/hooks/useTranslation';
import { ageGroup, isValidDate, isUnderage, minimumAge } from '@/Helpers/ageHelper';
import { isTeensFlow } from '@/Helpers/flowsHelper';
import {
  DateOfBirthStep,
  InternalTarget,
  UpdateStepObj,
  RecoveredFields,
  FlowConfig,
} from '@/Flows/types';
import AlertBox from '@/Components/AlertBox';
import sessionStorage from '@/core/storage/sessionStorage';
import styled from '@/core/styled';
import { FLOW_133_ISRAEL } from '../../../Flows';

type RecoveredField = keyof RecoveredFields;

const { QMResponsiveWidth } = commonStyles;

const StyledButton = styled(Button)(({ theme: { colors } }) => {
  return {
    transition: 'all 200ms ease-in-out',
    '&:not([disabled]):hover': {
      backgroundColor: colors.permaTropicalRainForest,
    },
  };
});

interface DateOfBirthProps {
  step: DateOfBirthStep;
  updateStep: (target: InternalTarget, updateStepObj: UpdateStepObj) => void;
  flowConfig: FlowConfig | undefined;
  handleClientDateOfBirth: (clientDateOfBirth: string) => void;
  redirectFrom?: number;
  // eslint-disable-next-line react/no-unused-prop-types
  qmPartnerCode?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  cpPartnerCode?: string;
  flowId: number;
  setRecoveredField: <T extends RecoveredField>(field: T, data: RecoveredFields[T]) => void;
  service: ServiceType | null;
}

interface DateOfBirthState {
  dateOfBirth: string;
  invalidDOB: boolean;
  missingDOB: boolean;
  missingOrInvalidFields: boolean;
  isUnderage: boolean;
  ageRange: { label?: string; value?: number | string };
  internalTarget: InternalTarget;
  errorMessage?: string;
  showError?: boolean;
  currentValue?: string;
  isLoading: boolean;
}

const DateOfBirth: FunctionComponent<DateOfBirthProps> = ({
  step,
  updateStep,
  flowConfig,
  handleClientDateOfBirth,
  redirectFrom,
  flowId,
  setRecoveredField,
  service,
}) => {
  const { t: tQuickmatchCommon } = useTranslation('quickmatch.common');
  const { formContainerRef, setShouldFocusFirstInvalidField } = useErrorFocus();
  const { colors } = useEmotionTheme();
  const isUnder18Flow = isTeensFlow(redirectFrom || flowId);

  const under18PageLink = isTeensFlow(redirectFrom || flowId)
    ? 'https://helpnow.talkspace.com/under-18-no-consent'
    : 'https://helpnow.talkspace.com/under-18';
  const under18PageLinkPsych = 'https://helpnow.talkspace.com/psych-under-18';

  const [state, setState] = useObjectState<DateOfBirthState>({
    dateOfBirth: '',
    invalidDOB: false,
    missingDOB: false,
    missingOrInvalidFields: false,
    isUnderage: false,
    ageRange: {},
    internalTarget: undefined,
    errorMessage: undefined,
    showError: false,
    currentValue: undefined,
    isLoading: false,
  });

  const getDateFormat = useCallback(
    (flowID: number) =>
      flowID === FLOW_133_ISRAEL
        ? {
            format: 'DD/MM/YYYY',
            dateFormat: 'dd/MM/YYYY',
            maskType: 'europeDate',
            label: <></>,
          }
        : {
            format: 'MM/DD/YYYY',
            dateFormat: 'MM/dd/YYYY',
            maskType: 'date',
            label: (
              <Label style={{ width: '100%', maxWidth: 335 }}>
                <Trans t={tQuickmatchCommon} i18nKey="dateOfBirthStep.inputLabel">
                  Date of birth
                </Trans>
              </Label>
            ),
          },
    [tQuickmatchCommon]
  );

  const validateDateOfBirth = () => {
    const dateFormat = getDateFormat(flowId).format;
    // a dob can be a a valid date but not valid for a specific qm flow due to age restrictions
    const invalidDOB = !isValidDate(state.dateOfBirth, redirectFrom || flowId, dateFormat);
    const underage = isUnderage(state.dateOfBirth, redirectFrom || flowId, dateFormat);
    let errorMessage;
    let showError = true;
    if (!state.dateOfBirth) {
      errorMessage = (
        <Trans t={tQuickmatchCommon} i18nKey="dateOfBirthStep.dobMissingError">
          Date of birth is missing
        </Trans>
      );
    } else if (underage) {
      errorMessage = (
        <Trans t={tQuickmatchCommon} i18nKey="dateOfBirthStep.underageError">
          You must be {{ minimum_age: minimumAge(flowId) }} or older
        </Trans>
      );
    } else if (invalidDOB) {
      errorMessage = (
        <Trans t={tQuickmatchCommon} i18nKey="dateOfBirthStep.dobInvalidError">
          Date of birth is invalid
        </Trans>
      );
    } else {
      showError = false;
    }
    setState({
      isUnderage: underage,
      invalidDOB,
      missingDOB: !state.dateOfBirth,
      missingOrInvalidFields: !state.dateOfBirth || invalidDOB || state.missingOrInvalidFields,
      errorMessage,
      showError,
    });
    return !showError;
  };

  const handleClick = () => {
    if (!validateDateOfBirth()) {
      // validating on click is necessary to account for dob info being stored in the session
      setShouldFocusFirstInvalidField(true);
    } else {
      handleClientDateOfBirth(
        moment(state.dateOfBirth, getDateFormat(flowId).format).format('YYYY-MM-DD')
      );
      setState({ isLoading: true });
      updateStep(state.internalTarget, {
        payfirst_step_id: step.analyticsId,
        payfirst_step_prompt: 'What is your age?',
        response_category_id: step.response_category_id,
        response_prompt: state.ageRange.label,
        response_value: state.ageRange.value ? (state.ageRange.value as string) : undefined,
      });
    }
  };

  const ageError = () =>
    isUnder18Flow && state.ageRange && state.ageRange.value === 'Under 13' ? 13 : 18;

  const getInternalTarget = useCallback(
    (age: number) => {
      switch (true) {
        case age >= 18:
          return step?.targets?.adult;
        // Psychiatry under 18
        case age < 18 && (flowConfig?.serviceType === 'psychiatry' || service === 'psychiatry'):
          return under18PageLinkPsych;
        case age >= 13 && age < 18:
          return isUnder18Flow ? step?.targets?.teen : under18PageLink;
        default:
          return isUnder18Flow ? step?.targets?.under13 : under18PageLink;
      }
    },
    [flowConfig, isUnder18Flow, service, step, under18PageLink]
  );
  const setDateOfBirth = (dateOfBirth?: string) => {
    const age = moment().diff(moment(dateOfBirth, getDateFormat(flowId).format), 'years');
    setState({
      dateOfBirth,
      internalTarget: getInternalTarget(age),
      ageRange: ageGroup(age),
      invalidDOB: false,
      missingDOB: false,
      missingOrInvalidFields: false,
      isUnderage: false,
    });
  };

  const handleDateOfBirth = (e) => {
    setDateOfBirth(e.target.value);
  };

  // syncs local state with session state so DOB input is populated if user has already,
  // added it and navigated away
  useEffect(() => {
    let sessionState: Partial<DateOfBirthState> = {};
    const dateOfBirthData = sessionStorage.getItem('TSQM_DateOfBirth');
    const basicInformation = sessionStorage.getItem('TSQM_BasicInformation');

    if (dateOfBirthData) {
      sessionState = JSON.parse(dateOfBirthData);
    } else if (basicInformation) {
      sessionState = {
        ...sessionState,
        dateOfBirth: JSON.parse(basicInformation).dateOfBirth || '',
      };
    }

    let newState = {
      ...sessionState,
    };

    const dateOfBirthFromSessionState = sessionState?.dateOfBirth;
    // Set internalTarget based on dateOfBirth from sessionStorage
    if (dateOfBirthFromSessionState) {
      // parse the date from qm-api format
      const dateOfBirth = moment(dateOfBirthFromSessionState).format('MM/DD/YYYY');
      const age = moment().diff(moment(dateOfBirth), 'years');
      newState = {
        ...newState,
        dateOfBirth,
        internalTarget: getInternalTarget(age),
        ageRange: ageGroup(age),
      };
    }

    setState(newState);
  }, [getInternalTarget, setState]);

  // syncs sessionStorage with local state, ensuring dob info is saved so the dob input
  // is pre-populated if user goes back a step
  useEffect(() => {
    const validTypingDOB = isValidDate(
      state.dateOfBirth,
      redirectFrom || flowId,
      getDateFormat(flowId).format
    );

    if (state.dateOfBirth && !state.invalidDOB && validTypingDOB) {
      const { dateOfBirth, invalidDOB, missingDOB, missingOrInvalidFields, ageRange } = state;
      sessionStorage.setItem(
        'TSQM_DateOfBirth',
        JSON.stringify({
          // format the date how qm-api expects it
          dateOfBirth: moment(dateOfBirth).format('YYYY-MM-DD'),
          invalidDOB,
          missingDOB,
          missingOrInvalidFields,
          isUnderage: state.isUnderage,
          ageRange,
          internalTarget: state.internalTarget,
        })
      );
      setRecoveredField('dateOfBirth', sessionStorage.getItem('TSQM_DateOfBirth'));
    }
  }, [state, setRecoveredField, redirectFrom, flowId, getDateFormat]);

  const inputId = useUniqueID('inputId');
  const underAgeMessageId = useUniqueID('underAgeMessageId');
  const dateFormat = getDateFormat(flowId);
  return (
    <>
      <View style={{ display: 'contents' }} ref={formContainerRef}>
        {dateFormat.label}
        <Input
          dataQa={`${step.category || ''}Input`}
          id={inputId}
          ariaRequired
          date-format={dateFormat.dateFormat}
          onChange={handleDateOfBirth}
          maskType={dateFormat.maskType}
          value={state.dateOfBirth}
          placeholder={dateFormat.format}
          onBlur={validateDateOfBirth}
          wrappedInputProps={{
            isError: state.invalidDOB || state.missingDOB || state.isUnderage,
            errorMessage: state.errorMessage,
            containerStyle: QMResponsiveWidth,
          }}
        />
      </View>
      <StyledButton
        data-qa={`${step.category || ''}ContinueButton`}
        style={{ fontWeight: 700, ...QMResponsiveWidth }}
        primaryColor={colors.green}
        roundedFocusStyle
        onClick={handleClick}
        aria-describedby={state.isUnderage ? underAgeMessageId : undefined}
        disabled={!state.dateOfBirth}
        isLoading={state.isLoading}
      >
        <Trans t={tQuickmatchCommon} i18nKey="dateOfBirthStep.buttonText">
          Continue
        </Trans>
      </StyledButton>
      {state.isUnderage && (
        <AlertBox id={underAgeMessageId}>
          <Trans t={tQuickmatchCommon} i18nKey="dateOfBirthStep.underageAlertMessage">
            Talkspace cannot provide service to individuals under the age of{' '}
            {{ minimum_age: ageError() }} at this time. If you or anyone you know are in a crisis or
            may be in danger, please use{' '}
            <Link
              dataQa={`${step.category || ''}UnderageLink`}
              style={{ color: colors.permaErrorRed, fontWeight: 700 }}
              primaryColor={colors.permaErrorRed}
              roundedFocusStyle
              target="_blank"
              href={state.internalTarget !== null ? (state.internalTarget as string) : undefined}
            >
              these resources
            </Link>{' '}
            to get immediate help.
          </Trans>
        </AlertBox>
      )}
    </>
  );
};

export default DateOfBirth;
