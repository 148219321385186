import { useWatch } from 'react-hook-form';
import { RHFInput } from '@talkspace/react-toolkit';
import { useContinue, useFormSaver } from './hooks';
import { useEligibilityService, EligibilityServiceFormData } from '@/hooks/useEligibilityService';
import StatusModal from './StatusModal';
import { QuickEligibilityProps } from './types';
import countriesHelper from '@/Helpers/countriesHelper';
import EligibilityForm, {
  AA_TOOLTIP_STYLE,
  AdditionalFieldsParams,
  Styled,
} from './EligibilityForm';

const MemberIdEligibility = ({
  updateStep,
  step,
  insurancePayer,
  setReferralSource,
  referralSource,
}: QuickEligibilityProps) => {
  useFormSaver();

  const { result, done, errorType, canRetry, attempts, isSubmitting, submit } =
    useEligibilityService({
      maxAttempts: 3,
    });

  const data = useWatch() as any as EligibilityServiceFormData;

  useContinue({
    result,
    data,
    done,
    errorType,
    updateStep,
    step,
    insurancePayer,
  });

  const statusModalIsVisible = (isSubmitting || (attempts > 0 && canRetry)) && !done;
  const shouldRetry = attempts > 0 && !isSubmitting && canRetry;

  const handleOnSubmit = (formData: EligibilityServiceFormData) => {
    const parsedFormData = JSON.parse(JSON.stringify(formData));

    const { state: clientState, country } = countriesHelper.getStateAndCountryOverrides(
      parsedFormData.country,
      parsedFormData.state
    );

    parsedFormData.country = country;
    parsedFormData.state = clientState;

    return submit(parsedFormData);
  };

  const additionalFields = (props: AdditionalFieldsParams) => {
    const { setValue, isManualSignUpFlow } = props;
    return [
      <RHFInput
        fieldName="memberID"
        label="Member / Subscriber ID"
        tooltip="Located on your Health Plan ID card. Type the full ID, including letters and numbers."
        isRequired
        data-qa="member-id-input"
        onChange={(e) => {
          const newValue = e.target.value.replace(/\s/gi, '').toLowerCase();
          if (newValue !== e.target.value) {
            setValue('memberID', newValue);
          }
        }}
        labelStyle={{ alignSelf: 'flex-end' }}
        tooltipStyle={AA_TOOLTIP_STYLE}
        isDisabled={isManualSignUpFlow && 'memberID' in data && !!data.memberID}
      />,
    ];
  };

  const eligibilityForm = EligibilityForm({
    done,
    handleOnSubmit,
    isSubmitting,
    data,
    referralSource,
    setReferralSource,
    additionalFields,
  });

  if (result) {
    return null;
  }

  return (
    <Styled.View>
      <StatusModal
        visible={statusModalIsVisible}
        isCheckingCoverage={isSubmitting}
        showRetryButton={shouldRetry}
        onHandleRetry={() => submit(data)}
      />
      {eligibilityForm}
    </Styled.View>
  );
};

export default MemberIdEligibility;
