import {
  FlowStep,
  DropdownStep,
  MultiSelectStep,
  IntroductionStep,
  MatchesStep,
  ValidateVoucherStep,
  ExplanationStep,
  SelectStep,
  SelectDynamicStep,
  DateOfBirthStep,
  LoadingStep,
  ChooseAndPayClientWebStep,
  PsychiatryUnavailableStep,
  RegisterWithVoucherStep,
  SchedulerStep,
  BHCopayStep,
  UpdateNicknamePasswordStep,
  FreeTextStep,
  SurveyWizardStep,
  SurveyIntroductionStep,
  SurveyResultsStep,
  MatchConfirmationStep,
  LocationEligibilityStep,
  LocationEligibilityErrorStep,
  LocationEligibilityManualAccessCodeStep,
  PresentingProblemsStep,
  B2BForkStep,
  SecondaryInsuranceStep,
  DispatcherInQMStep,
  LastingPromotionStep,
  OneFormEligibilityStep,
  ServiceSelectionStep,
  RegisterWithoutPaymentStep,
  CurrentProviderStep,
  SessionModalityStep,
  ReviewPlanStep,
  InsuranceConfirmationStep,
  OutOfNetworkStep,
  QuickEligibilityStep,
  MemberAvailabilityStep,
  QuickEligibilityStatusStep,
  PreRegisterHoldExpiredStep,
  PreRegisterJwtExpiredStep,
  FileUploadStep,
  ManualSubmitStep,
  RequestReceivedStep,
  TeenBranchingStep,
  TeenNYCSummaryStep,
  TeenLetsLearnAboutYouStep,
  TeenYouAreNotAloneStep,
  TeenConsentHeadsUpStep,
  TeenThankYouForSharingStep,
  SelectDynamicStepResolved,
  LeadCaptureStep,
  TeensHereToHelpStep,
  TeensEligibleFreeTherapyStep,
  SchoolSelectStep,
  TeenZipcodeEligibilityStep,
  QuickEligibilityEnterMemberIDStep,
} from '../Flows/types';

import { getHasAnyCallbacks } from './dynamicStepHelpers';

export const isDropdownStep = (step: FlowStep): step is DropdownStep =>
  step.category === 'dropdown';

export const isMultiSelectStep = (step: FlowStep): step is MultiSelectStep =>
  step.category === 'multiselect';

export const isIntroductionStep = (step: FlowStep): step is IntroductionStep =>
  step.category === 'introduction1';

export const isMatchesStep = (step: FlowStep): step is MatchesStep => step.category === 'matches1';

export const isOneFormEligibilityStep = (step: FlowStep): step is OneFormEligibilityStep =>
  step.category === 'oneFormEligibility';

export const isSessionModalityStep = (step: FlowStep): step is SessionModalityStep =>
  step.category === 'sessionModality';

export const isValidateVoucherStep = (step: FlowStep): step is ValidateVoucherStep =>
  step.category === 'validateVoucher';

export const isExplanationStep = (step: FlowStep): step is ExplanationStep =>
  step.category === 'explanation';

export const isSelectStep = (step: FlowStep): step is SelectStep => step.category === 'select1';

export const isSelectDynamicStep = (step: FlowStep): step is SelectDynamicStep =>
  step.category === 'selectDynamic';

export const isSelectDynamicStepResolved = (step: FlowStep): step is SelectDynamicStepResolved =>
  step.category === 'selectDynamic' && !getHasAnyCallbacks(step);

export const isDateOfBirthStep = (step: FlowStep): step is DateOfBirthStep =>
  step.category === 'dateOfBirth';

export const isLoadingStep = (step: FlowStep): step is LoadingStep => step.category === 'loading1';

export const isChooseAndPayClientWebStep = (step: FlowStep): step is ChooseAndPayClientWebStep =>
  step.category === 'chooseAndPayClientWeb';

export const isPsychiatryUnavailableStep = (step: FlowStep): step is PsychiatryUnavailableStep =>
  step.category === 'psychiatryUnavailable';

export const isRegisterWithVoucherStep = (step: FlowStep): step is RegisterWithVoucherStep =>
  step.category === 'registerWithVoucher1';

export const isRegisterWithoutPaymentStep = (step: FlowStep): step is RegisterWithoutPaymentStep =>
  step.category === 'registerWithoutPayment';

export const isSchedulerStep = (step: FlowStep): step is SchedulerStep =>
  step.category === 'scheduler';

export const isBHCopayStep = (step: FlowStep): step is BHCopayStep => step.category === 'BHCopay';

export const isUpdateNicknamePasswordStep = (step: FlowStep): step is UpdateNicknamePasswordStep =>
  step.category === 'updateNicknamePassword1';

export const isFreeTextStep = (step: FlowStep): step is FreeTextStep =>
  step.category === 'freeText';

export const isSurveyIntroductionStep = (step: FlowStep): step is SurveyIntroductionStep =>
  step.category === 'surveyIntroduction';

export const isSurveyWizardStep = (step: FlowStep): step is SurveyWizardStep =>
  step.category === 'surveyWizard';

export const isSurveyResultsStep = (step: FlowStep): step is SurveyResultsStep =>
  step.category === 'surveyResults';

export const isMatchConfirmationStep = (step: FlowStep): step is MatchConfirmationStep =>
  step.category === 'matchConfirmation';

export const isLocationEligibilityStep = (step: FlowStep): step is LocationEligibilityStep =>
  step.category === 'locationEligibility';

export const isLocationEligibilityErrorStep = (
  step: FlowStep
): step is LocationEligibilityErrorStep => step.category === 'locationEligibilityError';

export const isLocationEligibilityManualAccessCodeStep = (
  step: FlowStep
): step is LocationEligibilityManualAccessCodeStep =>
  step.category === 'locationEligibilityManualAccessCode';

export const isPresentingProblemsStep = (step: FlowStep): step is PresentingProblemsStep =>
  step.category === 'presentingProblems';

export const isB2BForkStep = (step: FlowStep): step is B2BForkStep => step.category === 'b2bFork';

export const isSecondaryInsuranceStep = (step: FlowStep): step is SecondaryInsuranceStep =>
  step.category === 'secondaryInsurance';

export const isDispatcherInQMStep = (step: FlowStep): step is DispatcherInQMStep =>
  step.category === 'dispatcherInQM';

export const isLastingPromotionStep = (step: FlowStep): step is LastingPromotionStep =>
  step.category === 'lastingPromotion';

export const isStateCountrySelectionStep = (step: FlowStep): step is DropdownStep =>
  step.category === 'dropdown' && step.response_category_id === 6; // 6: client state of residency, from payfirst_step_response_categories

export const isServiceSelectionStep = (step: FlowStep): step is ServiceSelectionStep =>
  step.category === 'serviceSelection';

export const isCurrentProviderStep = (step: FlowStep): step is CurrentProviderStep =>
  step.category === 'currentProvider';

export const isReviewPlanStep = (step: FlowStep): step is ReviewPlanStep =>
  step.category === 'reviewPlan';

export const isInsuranceConfirmationStep = (step: FlowStep): step is InsuranceConfirmationStep =>
  step.category === 'insuranceConfirmation';

export const isOutOfNetworkStep = (step: FlowStep): step is OutOfNetworkStep =>
  step.category === 'outOfNetwork';

export const isQuickEligibilityStep = (step: FlowStep): step is QuickEligibilityStep =>
  step.category === 'quickEligibility';

export const isMemberAvailabilityStep = (step: FlowStep): step is MemberAvailabilityStep =>
  step.category === 'memberAvailability';

export const isQuickEligibilityEnterMemberIDStep = (
  step: FlowStep
): step is QuickEligibilityEnterMemberIDStep => step.category === 'quickEligibilityEnterMemberID';

export const isQuickEligibilityStatusStep = (step: FlowStep): step is QuickEligibilityStatusStep =>
  step.category === 'quickEligibilityStatus';

export const isPreRegisterHoldExpiredStep = (step: FlowStep): step is PreRegisterHoldExpiredStep =>
  step.category === 'preRegisterHoldExpired';

export const isPreRegisterJwtExpiredStep = (step: FlowStep): step is PreRegisterJwtExpiredStep =>
  step.category === 'preRegisterJwtExpired';

export const isFileUploadStep = (step: FlowStep): step is FileUploadStep =>
  step.category === 'fileUpload';

export const isManualSubmit = (step: FlowStep): step is ManualSubmitStep =>
  step.category === 'manualSubmit';

export const isRequestReceivedStep = (step: FlowStep): step is RequestReceivedStep =>
  step.category === 'requestReceived';

export const isTeenBranchingStep = (step: FlowStep): step is TeenBranchingStep =>
  step.category === 'teenBranching';

export const isTeenNYCSummaryStep = (step: FlowStep): step is TeenNYCSummaryStep =>
  step.category === 'teenNYCSummary';

export const isTeenLetsLearnAboutYouStep = (step: FlowStep): step is TeenLetsLearnAboutYouStep =>
  step.category === 'teenLetsLearnAboutYou';

export const isTeenYouAreNotAloneStep = (step: FlowStep): step is TeenYouAreNotAloneStep =>
  step.category === 'teenYouAreNotAlone';

export const isTeenConsentHeadsUpStep = (step: FlowStep): step is TeenConsentHeadsUpStep =>
  step.category === 'teenConsentHeadsUp';

export const isTeenThankYouForSharingStep = (step: FlowStep): step is TeenThankYouForSharingStep =>
  step.category === 'teenThankYouForSharing';

export const isLeadCaptureStep = (step: FlowStep): step is LeadCaptureStep =>
  step.category === 'leadCapture';

export const isTeensHereToHelpStep = (step: FlowStep): step is TeensHereToHelpStep =>
  step.category === 'teensHereToHelp';

export const isTeensEligibleFreeTherapyStep = (
  step: FlowStep
): step is TeensEligibleFreeTherapyStep => step.category === 'teensEligibleFreeTherapy';

export const isSchoolSelectStep = (step: FlowStep): step is SchoolSelectStep =>
  step.category === 'schoolSelect';

export const isTeenZipcodeEligibilityStep = (step: FlowStep): step is TeenZipcodeEligibilityStep =>
  step.category === 'teenZipcodeEligibility';
