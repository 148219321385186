import { FunctionComponent } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  BackNextButton,
  useEmotionTheme,
  ProgressBar,
  Filter,
  Big,
  TouchableView,
  View,
  LanguageSelector,
} from '@talkspace/react-toolkit';
import { useTranslationState } from '@talkspace/i18n/hooks/translationContext';
import styled from '../../core/styled';
import useWindowWidth from '../../hooks/useWindowWidth';
import useBackButton from '../StepLayout/hooks/useBackButton';
import { FlowStep } from '../../Flows/types';
import { apply1000pxMediaQuery } from '../../core/styled/styleHelpers';
import { trackEvent } from '@/utils/analytics/eventTracker';

interface NavProps {
  progressHelpers: { progressCurrent?: number; progressMax?: number; isProgressHidden?: boolean };
  showFiltersButton?: boolean;
  handleShowFiltersModal?: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  failedMatches?: boolean;
  step: FlowStep;
  isLoggedInUser?: boolean;
}

const StyledBackButton = styled(BackNextButton)({
  '@media screen and (max-width: 1000px)': {
    marginTop: -50,
  },
  position: 'fixed',
  left: 2,
  margin: '25px 15px 10px 25px',
  borderRadius: 5,
  padding: 5,
  zIndex: 2,
});

const StyledFiltersWrapper = styled(TouchableView)<{ isLoggedInUser?: boolean }>(
  ({ isLoggedInUser }) => {
    return {
      zIndex: 2,
      position: 'fixed',
      right: 2,
      marginRight: isLoggedInUser ? 60 : 23,
      marginTop: -50,
      marginBottom: 22,
      padding: 5,
      borderRadius: 5,
      '&:focus': {
        zIndex: 2,
      },
      '::-webkit-user-select': 'none',
      '::-moz-user-select': 'none',
      '::-ms-user-select': 'none',
      userSelect: 'none',
    };
  }
);

const LanguageSelectorWrapper = styled(View)({
  position: 'fixed',
  zIndex: 1001,
  top: 10,
  right: 40,
  ...apply1000pxMediaQuery({ right: 20 }),
});

const Nav: FunctionComponent<NavProps> = ({
  progressHelpers: { isProgressHidden, progressCurrent, progressMax },
  step,
  isLoggedInUser,
  showFiltersButton,
  handleShowFiltersModal,
}) => {
  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidth();
  const { isHidden: isBackButtonHidden, onPress: onPressBackButton } = useBackButton(step);
  const { isTranslationEnabled } = useTranslationState();

  const onLanguageChange = (selectedLanguage: string) => {
    trackEvent('Selected Locale', {
      language: selectedLanguage,
    });
  };

  return (
    <View
      style={{ marginTop: '6.8rem', boxSizing: 'border-box', minWidth: '0', color: colors.black }}
    >
      {typeof progressCurrent === 'number' && (
        <ProgressBar
          currentProgress={progressCurrent || 0}
          maxProgress={progressMax || 0}
          isHidden={isProgressHidden}
        />
      )}
      {!isBackButtonHidden && (
        <StyledBackButton
          dataQa={`${step.category || ''}Back`}
          height={23}
          onPress={onPressBackButton}
          primaryColor={colors.green}
          roundedFocusStyle
        />
      )}
      {showFiltersButton && (
        <StyledFiltersWrapper
          dataQa={`${step.category || ''}Filters`}
          aria-label="filters"
          align="center"
          justify="space-between"
          onPress={handleShowFiltersModal}
          roundedFocusStyle
          primaryColor={colors.green}
          isLoggedInUser={isLoggedInUser}
        >
          <View row>
            <Filter color={colors.green} style={{ marginRight: 7, marginTop: 2 }} />
            {!isMobile && <Big style={{ color: colors.green }}>Filters</Big>}
          </View>
        </StyledFiltersWrapper>
      )}
      {isTranslationEnabled && (
        <LanguageSelectorWrapper>
          <LanguageSelector
            dataQa="quickmatchLanguageSelector"
            onLanguageChange={onLanguageChange}
          />
        </LanguageSelectorWrapper>
      )}
    </View>
  );
};

export default Nav;
