import { useEffect } from 'react';
import {
  Button,
  RHFInput,
  RHFDateInput,
  RHFSelect,
  useEmotionTheme,
  ExtraHuge,
  Small,
  Large,
  Link,
  View,
} from '@talkspace/react-toolkit';
import { useWatch, useFormContext } from 'react-hook-form';
import {
  SESSION_STORAGE_MEMBER_DETAILS_KEY,
  getSessionStorageValuesFromJson,
} from 'ts-frontend/utils';
import { countries } from '@/Helpers/locales';
import styled from '@/core/styled';
import { stateOptions } from './util';
import { EligibilityServiceFormData } from '@/hooks/useEligibilityService/types';
import { DiscoveryServiceFormData } from '@/hooks/useDiscoveryService/types';

export const AA_TOOLTIP_STYLE = {
  width: 23,
  height: 34,
  alignItems: 'flex-end',
  display: 'flex',
  marginBottom: 0,
  paddingBottom: 6,
};

export const Styled = {
  Heading: styled(ExtraHuge)(() => {
    return {
      marginBottom: 10,
    };
  }),
  Subheading: styled(Large)(({ theme: { colors } }) => {
    return {
      fontSize: 16,
      color: colors.darkGray,
      marginBottom: 32,
    };
  }),
  View: styled(View)(() => {
    return {
      maxWidth: 430,
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    };
  }),
  Form: styled.form(() => {
    return {
      maxWidth: 335,
      width: '100%',
      outline: 'none',
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
      alignItems: 'stretch',
      textAlign: 'center',
    };
  }),
  FieldWrapper: styled(View)({
    width: '100%',
    maxWidth: 165,
    '@media(max-width: 350px)': {
      maxWidth: 335,
    },
  }),
  Row: styled(View)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: 335,
    '@media(max-width: 350px)': {
      display: 'inline-block',
    },
  }),
  Footer: styled(View)({
    maxWidth: 335,
    marginTop: 25,
  }),
  Error: styled(View)(({ theme: { colors } }) => {
    return {
      display: 'block',
      maxWidth: 335,
      marginTop: 12,
      padding: 20,
      backgroundColor: colors.permaProvincialPink,
      borderRadius: 9,
      color: colors.permaOuterSpaceNew,
      textAlign: 'left',
      '> a': {
        color: colors.permaErrorRed,
      },
    };
  }),
};

export interface AdditionalFieldsParams {
  setValue: (key: string, value: string) => void;
  isManualSignUpFlow: boolean;
}

interface EligibilityFormParams {
  data: EligibilityServiceFormData | DiscoveryServiceFormData;
  handleOnSubmit: (formData: EligibilityServiceFormData | DiscoveryServiceFormData) => void;
  isSubmitting: boolean;
  done: boolean;
  referralSource: string;
  setReferralSource: (referralSource: string) => void;
  additionalFields?: (props: AdditionalFieldsParams) => JSX.Element[];
}

const EligibilityForm = ({
  handleOnSubmit,
  data,
  isSubmitting,
  done,
  referralSource,
  setReferralSource,
  additionalFields,
}: EligibilityFormParams) => {
  const { colors } = useEmotionTheme();

  const formContext = useFormContext();
  const { handleSubmit, setValue } = formContext;

  const isUnder13 = formContext.formState.errors.dob?.type === 'under13';
  const isManualSignUpFlow = referralSource === 'manualSignUp';

  const state = useWatch({
    name: 'state',
  });

  useEffect(() => {
    const { referralSource: sessionReferralSource } = getSessionStorageValuesFromJson(
      SESSION_STORAGE_MEMBER_DETAILS_KEY,
      ['referralSource']
    );
    if (sessionReferralSource?.value) {
      setReferralSource(sessionReferralSource.value);
    }
  }, [setReferralSource]);

  return (
    <Styled.Form onSubmit={handleSubmit(handleOnSubmit)}>
      <Styled.Heading>Check your coverage</Styled.Heading>
      <Styled.Subheading>We’ll use this to determine your session cost</Styled.Subheading>
      <Styled.Row>
        <Styled.FieldWrapper>
          <RHFInput
            fieldName="firstName"
            label="Patient first name"
            placeholder="First name"
            isRequired
            data-qa="first-name-input"
            isDisabled={isManualSignUpFlow && !!data.firstName}
          />
        </Styled.FieldWrapper>
        <Styled.FieldWrapper>
          <RHFInput
            fieldName="lastName"
            label="Patient last name"
            placeholder="Last name"
            isRequired
            data-qa="last-name-input"
            isDisabled={isManualSignUpFlow && !!data.lastName}
          />
        </Styled.FieldWrapper>
      </Styled.Row>
      <RHFDateInput
        fieldName="dob"
        label="Date of birth"
        isRequired
        data-qa="dob-input"
        containerStyle={{ marginBottom: 0 }}
        isDisabled={isManualSignUpFlow && !!data.dob}
      />
      <RHFSelect
        fieldName="state"
        label="Location"
        options={stateOptions}
        allowMobileSearch
        registerOptionValue
        placeholder="Select your location"
        tooltip="Select the location associated with your insurance."
        labelStyle={{ paddingBottom: 4 }}
        labelContainerStyle={{ alignItems: 'flex-end', marginBottom: 0 }}
        tooltipProps={{
          messageStyle: { marginRight: 10 },
          buttonStyle: AA_TOOLTIP_STYLE,
        }}
        isDisabled={isManualSignUpFlow && !!data.state}
      />
      {state === 'other' && (
        <RHFSelect
          fieldName="country"
          label="Country"
          options={countries}
          allowMobileSearch
          registerOptionValue
          placeholder="Select your country"
        />
      )}
      {additionalFields ? additionalFields({ setValue, isManualSignUpFlow }) : null}
      {isUnder13 && (
        <Styled.Error>
          Talkspace cannot provide service to individuals under the age of 13 at this time. If you
          or anyone you know are in a crisis or may be in danger, please use the following{' '}
          <Link href="https://google.com">resources</Link> to get immediate help.
        </Styled.Error>
      )}
      <Button
        text="Continue"
        roundedFocusStyle
        disabled={done}
        isLoading={isSubmitting}
        primaryColor={colors.green}
        dataQa="shortEligibilityCTA"
        style={{ alignSelf: 'center', width: '100%', fontWeight: 'bold' }}
      />
      <Styled.Footer>
        <Small>
          Talkspace is committed to protecting your privacy and follows HIPAA, state and federal
          laws.
        </Small>
      </Styled.Footer>
    </Styled.Form>
  );
};

export default EligibilityForm;
