import {
  View,
  Small,
  ScrollView,
  Big,
  Button,
  useEmotionTheme,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { useState } from 'react';
import { Option } from 'inRoomScheduling/components/SelectTimeslot/SelectTimeslotPreferredTimes';
import { isApple } from 'ts-frontend/utils/device';
import { UpdateStep, MemberAvailabilityStep } from '@/Flows/types';
import { DayOfWeek, HomePageState } from '@/Components/HomePage';
import styled from '../../../core/styled';
import { trackEvent } from '@/utils/analytics/events';

const ScrollViewStyled = styled(ScrollView)({
  maxHeight: 'calc(100vh - 400px)',
  width: 355,
  overflowX: 'clip',
  paddingBottom: 40,
  paddingLeft: 5,
  alignItems: 'center',
});

interface MemberAvailabilityProps {
  updateStep: UpdateStep;
  step: MemberAvailabilityStep;
  memberAvailability: HomePageState['memberAvailability'];
}

export type Day =
  | 'Mondays'
  | 'Tuesdays'
  | 'Wednesdays'
  | 'Thursdays'
  | 'Fridays'
  | 'Saturdays'
  | 'Sundays';
export type TimeOfDay = 'Morning' | 'Afternoon' | 'Evening';
type AvailabilitySlot = {
  startTime: string;
  endTime: string;
};
type TimeBucket = {
  name: TimeOfDay;
  timeRange: string;
  availability: AvailabilitySlot;
};
export type SelectedOption = { day: Day; timeName: TimeOfDay; timeRange: string };

const SUGGESTED_DAYS: Day[] = [
  'Mondays',
  'Tuesdays',
  'Wednesdays',
  'Thursdays',
  'Fridays',
  'Saturdays',
  'Sundays',
];
const SELECTIONS_REQUIRED = 1;
const DAILY_TIME_BUCKETS: TimeBucket[] = [
  {
    name: 'Morning',
    timeRange: '7am - 12pm',
    availability: {
      startTime: '7:00',
      endTime: '12:00',
    },
  },
  {
    name: 'Afternoon',
    timeRange: '12pm - 5pm',
    availability: {
      startTime: '12:00',
      endTime: '17:00',
    },
  },
  {
    name: 'Evening',
    timeRange: '5pm - 9pm',
    availability: {
      startTime: '17:00',
      endTime: '21:00',
    },
  },
];

const GradientBlock = () => (
  <span
    style={{
      zIndex: 1,
      width: '100%',
      height: 50,
      marginTop: -50,
      background: 'linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1))',
    }}
  />
);

const parseTime = (time) => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes; // Convert time to minutes past midnight
};
const MemberAvailability = (props: MemberAvailabilityProps) => {
  const { updateStep, step, memberAvailability } = props;

  const { colors } = useEmotionTheme();

  const deparseMemberAvailability = (
    memberAvailabilityData: HomePageState['memberAvailability']
  ): Array<SelectedOption> => {
    const previouslySelected: Array<SelectedOption> = [];

    memberAvailabilityData?.forEach((it) => {
      it.availability.forEach((availability) => {
        DAILY_TIME_BUCKETS.forEach((bucket) => {
          if (
            parseTime(availability.startTime) <= parseTime(bucket.availability.startTime) &&
            parseTime(availability.endTime) >= parseTime(bucket.availability.endTime)
          ) {
            previouslySelected.push({
              day: `${it.dayOfWeek}s` as Day,
              timeName: bucket.name,
              timeRange: bucket.timeRange,
            });
          }
        });
      });
    });

    return previouslySelected;
  };

  const [selectedOptions, setSelectedOptions] = useState<Array<SelectedOption>>(
    deparseMemberAvailability(memberAvailability)
  );
  const { isMobile } = useWindowWidthState();

  const selectedCount = selectedOptions.length;
  const leftToSelect = SELECTIONS_REQUIRED - selectedCount;
  const canProceed = leftToSelect <= 0;

  const parseMemberAvailability = (
    selectedOptionsData: Array<SelectedOption>
  ): HomePageState['memberAvailability'] => {
    const memberAvailabilityData: HomePageState['memberAvailability'] = [];
    selectedOptionsData.forEach((selectedOptionsItem) => {
      const dayIndex = memberAvailabilityData.findIndex(
        (memberAvailabilityItem) =>
          `${memberAvailabilityItem.dayOfWeek}s` === selectedOptionsItem.day
      );
      const dailyTimeBucket = DAILY_TIME_BUCKETS.find(
        (bucket) => bucket.name === selectedOptionsItem.timeName
      );
      if (dayIndex >= 0) {
        memberAvailabilityData[dayIndex].availability.push({
          startTime: dailyTimeBucket!.availability.startTime,
          endTime: dailyTimeBucket!.availability.endTime,
        });

        if (memberAvailabilityData[dayIndex].availability.length === DAILY_TIME_BUCKETS.length) {
          memberAvailabilityData[dayIndex].alwaysAvailable = true;
        }
      } else {
        memberAvailabilityData?.push({
          dayOfWeek: selectedOptionsItem.day.slice(0, -1) as DayOfWeek,
          availability: [
            {
              startTime: dailyTimeBucket!.availability.startTime,
              endTime: dailyTimeBucket!.availability.endTime,
            },
          ],
          alwaysAvailable: false,
        });
      }
    });
    return memberAvailabilityData;
  };

  const onPressContinue = () => {
    const memberAvailabilityData = parseMemberAvailability(selectedOptions);
    updateStep(step.buttonTarget, {
      memberAvailability: memberAvailabilityData,
    });
    trackEvent('Availability Preferences Continue', {
      availability: selectedOptions.map((it) => `${it.day} ${it.timeName} ${it.timeRange}`),
    });
  };

  const onPressAvailableAnyTime = () => {
    const memberAvailabilityData: HomePageState['memberAvailability'] = [];

    SUGGESTED_DAYS.forEach((day) => {
      memberAvailabilityData.push({
        dayOfWeek: day.slice(0, -1) as DayOfWeek,
        availability: [],
        alwaysAvailable: true,
      });
      DAILY_TIME_BUCKETS.forEach((bucket) => {
        memberAvailabilityData[memberAvailabilityData.length - 1].availability.push({
          startTime: bucket.availability.startTime,
          endTime: bucket.availability.endTime,
        });
      });
    });
    updateStep(step.buttonTarget, { memberAvailability: memberAvailabilityData });
    trackEvent('Availability Preferences Always Available', {
      availability: deparseMemberAvailability(memberAvailabilityData).map(
        (it) => `${it.day} ${it.timeName} ${it.timeRange}`
      ),
    });
  };

  const mobileContentHeight = isApple ? '78vh' : '88vh';
  return (
    <View align="center" style={{ height: isMobile ? mobileContentHeight : undefined }}>
      <Small
        variant="smallDarkGrey"
        style={{ textAlign: 'center', margin: `0 15px 35px`, maxWidth: 280 }}
      >
        Select all that apply
      </Small>

      <ScrollViewStyled>
        {SUGGESTED_DAYS.map((day) => (
          <View style={{ marginTop: 16, textAlign: 'left' }}>
            <Big>{day}</Big>
            <View row>
              {DAILY_TIME_BUCKETS.map((bucket, i) => (
                <Option
                  hideSelectionCircle
                  day={day as Day}
                  timeBucket={bucket}
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                  isLast={i === DAILY_TIME_BUCKETS.length - 1}
                />
              ))}
            </View>
          </View>
        ))}
      </ScrollViewStyled>
      <GradientBlock />
      <View style={{ width: '100%', padding: '0 5px' }}>
        {canProceed && (
          <Button
            text="Continue"
            onPress={onPressContinue}
            disabled={!canProceed}
            style={{ marginTop: 4 }}
            stretch
            dataQa="selectTimeslotPreferredTimesContinue"
          />
        )}
        <Button
          text="I'm available any time"
          onPress={onPressAvailableAnyTime}
          style={{
            marginTop: 4,
            color: colors.greenText,
            backgroundColor: 'transparent',
          }}
          stretch
          dataQa="selectTimeslotPreferredTimesAvailableAnyTime"
        />
      </View>
    </View>
  );
};

export default MemberAvailability;
