import {
  EmotionStyle,
  Link,
  RouterLinkWrapper,
  Tiny,
  useEmotionTheme,
  View,
} from '@talkspace/react-toolkit';
import appConfigs from '../../utils/configs';

const SignupFooter = ({ containerStyle = {} }: { containerStyle?: EmotionStyle }) => {
  const { colors } = useEmotionTheme();

  return (
    <View align="center" style={{ width: 335, marginTop: 20, ...containerStyle }}>
      <View>
        <Tiny>
          Already have an account?
          <RouterLinkWrapper primaryColor={colors.green} roundedFocusStyle>
            <Link
              href={`${appConfigs.endpoints.clientWebEndpoint}/login`}
              style={{ color: colors.greenText }}
            >
              <Tiny variant="tinyBoldGreen" inline>
                Log in
              </Tiny>
            </Link>
          </RouterLinkWrapper>
        </Tiny>
      </View>
    </View>
  );
};

export default SignupFooter;
