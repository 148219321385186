import { AccountType, HomePageState } from '../Components/HomePage/types';
import { states } from '../Helpers/locales';
import { shouldSkipOrRemovePreferredModality } from '../Helpers/preferredModalityHelper';
import getParamByName from '../utils/queryString';
// This import in particular needs user interaction, therefore, "safe" to be a cycle
// eslint-disable-next-line import/no-cycle
import { getClientFlow, getClientFlowConfig } from './index';
// eslint-disable-next-line import/no-cycle
import { FLOW_138_B2B_VOUCHER_GENERIC_V2 } from './Flow138';
// eslint-disable-next-line import/no-cycle
import { FLOW_7_B2B_COPAY_GENERIC } from './Flow7';
import { EligibilityType, OneFormEligibilityStep, QMFlow, UpdateStepObj } from './types';
import {
  allRegistrationPagesSteps,
  ineligiblePromo,
  initFlowConfig,
  registrationStepsValidation,
  skipIfExperiment,
  skipToStepIfDoesntMeetAvailabilityPreferencesRequirements,
} from './utils';
import { QM_COPY } from '../utils/qmCopy';

const OFFER_ID = 62;
const INITIAL_OFFER_STEP_ID = 12;
const MATCHES_STEP_ID = 10;

const getRegistrationStepIDByFlowID = (state: HomePageState, answer?: UpdateStepObj): number => {
  const { organizationFlowID, partnerFlowID } = state;
  let { qmPartnerCode, cpPartnerCode } = state;
  let accessCodeType = getParamByName('accessCodeType') as AccessCodeType | null;

  if (
    !(accessCodeType || qmPartnerCode || cpPartnerCode) &&
    (answer?.accessCodeType || answer?.accessCode)
  ) {
    accessCodeType = answer.accessCodeType || null;
    // Attempt to find the cpPartnerCode first
    if (accessCodeType && accessCodeType === 'cpPartnerCode') {
      cpPartnerCode = answer.accessCode || '';
    }
    // Default to qmPartnerCode
    if (!cpPartnerCode) {
      qmPartnerCode = answer.accessCode || '';
    }
  }
  const flowID = organizationFlowID || partnerFlowID;
  let flow = flowID && getClientFlow(flowID);
  if (cpPartnerCode || accessCodeType === 'cpPartnerCode') {
    flow = getClientFlow(FLOW_7_B2B_COPAY_GENERIC);
  }
  if (qmPartnerCode || accessCodeType === 'qmPartnerCode') {
    flow = getClientFlow(FLOW_138_B2B_VOUCHER_GENERIC_V2);
  }
  if (flow) {
    const registrationStepIndexes = flow.steps
      .map((step) => registrationStepsValidation.findIndex((validationFun) => validationFun(step)))
      .filter((index) => index > -1);
    if (registrationStepIndexes.length > 0) {
      return INITIAL_OFFER_STEP_ID + registrationStepIndexes[0];
    }
  }
  return INITIAL_OFFER_STEP_ID;
};

const skipModalitySelectionIfIrrelevant =
  (skipToStepID?: number) => (state: HomePageState, answer?: UpdateStepObj) => {
    const allowedModalities = answer?.allowedModalities || state.allowedModalities || undefined;
    if (shouldSkipOrRemovePreferredModality({ ...state, allowedModalities })) {
      return skipToStepID || getRegistrationStepIDByFlowID(state, answer); // skip to registration if no modalities found.
    }
    return undefined;
  };

const skipReviewPlanIfIrrelevant =
  (skipToStepID?: number) => (state: HomePageState, answer?: UpdateStepObj) => {
    const accountType = answer?.accountType || state.accountType;
    const manualFlowID = answer?.manualFlowID || state.manualFlowID;
    const isCouldNotBeVerified = answer?.isCouldNotBeVerified || state.isCouldNotBeVerified;

    const manualFlowConfig = manualFlowID ? getClientFlowConfig(manualFlowID) : undefined;
    const isManualFlow =
      manualFlowConfig?.eligibilityType === EligibilityType.manual ||
      getClientFlowConfig(state.organizationFlowID || state.partnerFlowID || state.redirectFrom)
        ?.eligibilityType === EligibilityType.manual;
    if (accountType && [AccountType.EAP, AccountType.BH].includes(accountType) && !isManualFlow) {
      return undefined;
    }
    if (isCouldNotBeVerified) {
      return undefined;
    }
    return skipModalitySelectionIfIrrelevant(skipToStepID)(state, answer) || 30; // go to modalities
  };

const skipReviewPlanIfOrganization = (state: HomePageState, answer?: UpdateStepObj) => {
  if (state.organizationFlowID) return getRegistrationStepIDByFlowID(state, answer);
  return undefined;
};

const skipEligibilityForDTE = (state: HomePageState, answer?: UpdateStepObj) => {
  const { organizationFlowID } = answer || {};
  // If we successfully received a qmFlowID and the target flow has an eligibility type, show form
  if (
    organizationFlowID &&
    getClientFlowConfig(organizationFlowID)?.eligibilityType !== undefined
  ) {
    return undefined;
  }
  return skipModalitySelectionIfIrrelevant(MATCHES_STEP_ID)(state, answer) || 30; // Also skipping Preferred Modality step if needed
};

// Omitting category for readability
const ONE_FORM_ELIGIBILITY_DEFAULT_STEP_CONFIG: Omit<OneFormEligibilityStep, 'id' | 'category'> = {
  analyticsId: 54,
  response_category_id: 9,
  // nextQuestionActions: [goToMatchesOrRegistration],
  ...ineligiblePromo,
};

/** Service Specific Funnels: Therapy */
export const FLOW_100_ELIGIBILITY_QUESTIONS_THERAPY = 100;

const flow: QMFlow = {
  flowId: FLOW_100_ELIGIBILITY_QUESTIONS_THERAPY,
  flowConfig: {
    ...initFlowConfig,
    serviceType: 'psychotherapy',
    isB2B: false,
    isTeen: true,
    shouldHideServiceSelection: true,
    version: 1,
  },
  steps: [
    {
      id: 1,
      prompt: 'What led you to seek help?',
      analyticsId: 54,
      response_category_id: 2,
      category: 'presentingProblems',
      internalTarget: 135,
      selfServe: {
        field: 'fieldsOfExpertise',
        type: Array,
      },
      heading1: null,
      heading2: "To begin, tell us why you're looking for help today.",
      progressCurrent: 1,
      progressMax: 11,
      tip: 'findTheRightMatch',
    },
    {
      id: 135,
      category: 'memberAvailability',
      prompt: 'When are you generally available for therapy?',
      heading2: 'When are you generally available for therapy?',
      skipQuestionActions: [
        skipToStepIfDoesntMeetAvailabilityPreferencesRequirements(2),
        skipIfExperiment({
          experimentName: 'clientAvailabilityPreferencesExperiment',
          variant: 'control',
          nextStepId: 2,
          shouldTrack: true,
        }),
      ],
      buttonTarget: 2,
    },
    {
      id: 2,
      prompt: 'Preferred provider gender',
      analyticsId: 57,
      response_category_id: 3,
      category: 'select1',
      heading2: 'What gender would you prefer in a provider?',
      selfServe: {
        field: 'therapistGender',
      },
      buttons: [
        {
          text: 'No preference',
          externalTarget: null,
          internalTarget: 3,
          answerValue: undefined,
        },
        {
          text: 'Female',
          externalTarget: null,
          internalTarget: 3,
          answerValue: 2,
        },
        {
          text: 'Male',
          externalTarget: null,
          internalTarget: 3,
          answerValue: 1,
        },
        {
          text: "I'm not sure yet",
          externalTarget: null,
          internalTarget: 3,
          answerValue: undefined,
        },
      ],
      progressCurrent: 2,
      progressMax: 11,
    },
    // matching questions
    {
      id: 3,
      prompt: 'My gender is',
      analyticsId: 62,
      response_category_id: 4,
      category: 'select1',
      heading2: 'What gender do you identify with?',
      buttons: [
        {
          text: 'Female',
          externalTarget: null,
          internalTarget: 4,
          answerValue: 2,
        },
        {
          text: 'Male',
          externalTarget: null,
          internalTarget: 4,
          answerValue: 1,
        },
        {
          text: 'Transgender female',
          externalTarget: null,
          internalTarget: 4,
          answerValue: 4,
        },
        {
          text: 'Transgender male',
          externalTarget: null,
          internalTarget: 4,
          answerValue: 3,
        },
        {
          text: 'Gender queer',
          externalTarget: null,
          internalTarget: 4,
          answerValue: 5,
        },
        {
          text: 'Gender variant',
          externalTarget: null,
          internalTarget: 4,
          answerValue: 6,
        },
        {
          text: 'Other',
          externalTarget: null,
          internalTarget: 4,
          answerValue: 7,
        },
        {
          text: 'Non-binary',
          externalTarget: null,
          internalTarget: 4,
          answerValue: 8,
        },
      ],
      skipText: 'Skip',
      skipExternalTarget: null,
      skipInternalTarget: 4,
      progressCurrent: 3,
      progressMax: 11,
    },
    {
      id: 4,
      prompt: 'How would you rate your sleeping habits?',
      analyticsId: 59,
      response_category_id: 7,
      category: 'select1',
      heading2: 'How would you rate your sleeping habits?',
      buttons: [
        {
          text: 'Excellent',
          externalTarget: null,
          internalTarget: 5,
          answerValue: 'Excellent',
        },
        {
          text: 'Good',
          externalTarget: null,
          internalTarget: 5,
          answerValue: 'Good',
        },
        {
          text: 'Fair',
          externalTarget: null,
          internalTarget: 5,
          answerValue: 'Fair',
        },
        {
          text: 'Poor',
          externalTarget: null,
          internalTarget: 5,
          answerValue: 'Poor',
        },
      ],
      progressCurrent: 4,
      progressMax: 11,
    },
    {
      id: 5,
      prompt: 'How would you rate your physical health?',
      analyticsId: 60,
      response_category_id: 7,
      category: 'select1',
      heading2: 'How would you rate your current physical health?',
      buttons: [
        {
          text: 'Excellent',
          externalTarget: null,
          internalTarget: 6,
          answerValue: 'Excellent',
        },
        {
          text: 'Good',
          externalTarget: null,
          internalTarget: 6,
          answerValue: 'Good',
        },
        {
          text: 'Fair',
          externalTarget: null,
          internalTarget: 6,
          answerValue: 'Fair',
        },
        {
          text: 'Poor',
          externalTarget: null,
          internalTarget: 6,
          answerValue: 'Poor',
        },
      ],
      progressCurrent: 5,
      progressMax: 11,
    },
    {
      id: 6,
      prompt: 'What is your age?',
      analyticsId: 61,
      response_category_id: 5,
      category: 'dateOfBirth',
      heading2: "What's your date of birth?",
      heading3: 'As a healthcare provider, we must ask this.',
      targets: {
        adult: 7,
        teen: 21,
        under13: 'https://helpnow.talkspace.com/under-13',
      },
      progressCurrent: 6,
      progressMax: 11,
    },
    // teen consent (age 13 - 18)
    {
      id: 21,
      prompt: 'Consent',
      analyticsId: 562,
      response_category_id: 7, // TODO
      heading2:
        'Based on your age and state, parent or guardian consent may be needed. Does this work for you?',
      category: 'select1',
      buttons: [
        {
          text: 'Yes',
          answerValue: 'Yes',
          externalTarget: null,
          internalTarget: 7,
        },
        {
          text: 'No, show me other resources',
          answerValue: 'No, show me other resources',
          externalTarget: 'https://helpnow.talkspace.com/under-18-no-consent',
          internalTarget: null,
        },
      ],
      progressCurrent: 7,
      progressMax: 11,
    },
    {
      id: 7,
      prompt: QM_COPY.state.prompt,
      analyticsId: 63,
      response_category_id: 6,
      category: 'dropdown',
      heading2: QM_COPY.state.title,
      heading3: QM_COPY.state.subtitle,
      selectPlaceholder: QM_COPY.state.placeholder,
      selectOptions: states,
      buttonText: QM_COPY.state.continue,
      buttonTarget: 8,
      skipExternalTarget: null,
      progressCurrent: 8,
      progressMax: 11,
    },
    {
      id: 8,
      category: 'dispatcherInQM',
      initialDispatcherStep: 'organizationName',
      internalTarget: 9,
      progressCurrent: 10,
      progressMax: 11,
      nextQuestionActions: [skipEligibilityForDTE],
    },
    {
      id: 9,
      category: 'oneFormEligibility',
      buttonTarget: 32,
      progressCurrent: 11,
      progressMax: 11,
      ...ONE_FORM_ELIGIBILITY_DEFAULT_STEP_CONFIG,
      nextQuestionActions: [skipReviewPlanIfIrrelevant(MATCHES_STEP_ID)],
    },
    {
      id: 30,
      category: 'sessionModality',
      buttonTarget: MATCHES_STEP_ID,
    },
    {
      id: MATCHES_STEP_ID,
      prompt: 'Matches',
      category: 'matches1',
      buttonTarget: 11,
      zeroMatchTarget: 11,
      matchOverlay: true,
      matchOverlayCampaignType: 'quickmatch90_1',
      canMatchOverlayBeSkipped: true,
      showCustomerRegistrationModalWithoutPayment: true,
    },
    {
      id: 11,
      category: 'oneFormEligibility',
      buttonTarget: 33,
      noCoverageButtonTarget: INITIAL_OFFER_STEP_ID,
      noCoverageHideEAP: true,
      nextQuestionActions: [skipReviewPlanIfOrganization, skipReviewPlanIfIrrelevant()],
      disableOrganization: true,
      ...ONE_FORM_ELIGIBILITY_DEFAULT_STEP_CONFIG,
    },
    {
      id: 31,
      category: 'sessionModality',
      buttonTarget: INITIAL_OFFER_STEP_ID,
      nextQuestionActions: [getRegistrationStepIDByFlowID],
    },
    {
      id: 32,
      category: 'reviewPlan',
      buttonTarget: 30,
      nextQuestionActions: [skipModalitySelectionIfIrrelevant(MATCHES_STEP_ID)],
    },
    {
      id: 33,
      category: 'reviewPlan',
      buttonTarget: 31,
      nextQuestionActions: [skipModalitySelectionIfIrrelevant()],
    },
    ...allRegistrationPagesSteps({
      initialStepID: INITIAL_OFFER_STEP_ID,
      offersPageSettings: {
        offerID: OFFER_ID,
        previousStepsUntilMatches: 2,
      },
    }),
  ],
};

export default flow;
