import { states } from '../Helpers/locales';
import { EligibilityType, QMFlow } from './types';
import {
  skipToStepIfClientAgeExists,
  skipToStepIfClientAgeIsTeen,
  initFlowConfig,
  languageMatchingButtons,
  languageMatchingSkipIfNoLanguages,
  languageMatchingSelfServe,
  languageMatchingFilterHeading1,
  languageMatchingFilterHeading2,
  languageMatchingFilterButtons,
  routeToLanguageMatchingIfExperiment,
  skipToStepIfAnswerValue,
  skipToStepIfDoesntMeetAvailabilityPreferencesRequirements,
  skipIfExperiment,
} from './utils';

import { HomePageState, AnswerValue, AccountType } from '../Components/HomePage/types';
import { shouldSkipOrRemovePreferredModality } from '../Helpers/preferredModalityHelper';
import { LANGUAGE_ID_ENGLISH } from '../Helpers/matchingLanguages';
import flow2 from './Flow2';
import { getClientFlowConfig } from './index';
import { presentingProblemSteps } from './steps';

const skipStepsForPsychiatryBH = (state: HomePageState) => {
  // skip a few steps for BH psychiatry users
  const { service } = state;
  if (service === 'psychiatry') {
    return 10;
  }
  return undefined;
};

const promptLastingPromotion = (_state: HomePageState, answer: AnswerValue) => {
  // prompt Lasting promotion if url has `lasting=1` and relationship presenting problem is selected
  const urlParams = new URLSearchParams(window.location.search);
  // response_value 20: I'm having difficulty in my relationship
  if (Number(urlParams.get('lasting')) === 1 && Number(answer.response_value) === 20) {
    return 3;
  }
  return undefined;
};

const skipModalitySelectionIfIrrelevant = (state: HomePageState) => {
  if (shouldSkipOrRemovePreferredModality(state)) {
    return 19;
  }

  return undefined;
};

const skipReviewPlanIfIrrelevant = (state: HomePageState) => {
  const { accountType } = state;
  const isPsychiatry = state?.service === 'psychiatry';
  const manualFlowID = state?.manualFlowID;

  const manualFlowConfig = manualFlowID ? getClientFlowConfig(manualFlowID) : undefined;
  const isManualFlow =
    manualFlowConfig?.eligibilityType === EligibilityType.manual ||
    getClientFlowConfig(state.redirectFrom)?.eligibilityType === EligibilityType.manual;

  if (
    accountType &&
    [AccountType.EAP, AccountType.BH].includes(accountType) &&
    !isManualFlow &&
    !isPsychiatry
  ) {
    return undefined;
  }
  return skipModalitySelectionIfIrrelevant(state) || 30; // go to modalities
};

/** Generic B2B (with voucher) V2 | Originally Flow 2 */
export const FLOW_138_B2B_VOUCHER_GENERIC_V2 = 138;

const flow: QMFlow = {
  flowId: FLOW_138_B2B_VOUCHER_GENERIC_V2,
  flowConfig: {
    ...initFlowConfig,
    ...flow2.flowConfig,
    version: 1,
    // Allow teens to use DTE (MEMBER-3106)
    isTeen: true,
    i18nNamespace: 'quickmatch.flow138',
  },
  steps: [
    {
      id: 1,
      prompt: 'Welcome to QuickMatch',
      analyticsId: 53,
      category: 'introduction1',
      response_category_id: 1,
      progressCurrent: 0,
      progressMax: 10,
      heading1: 'Welcome to <br/>Talkspace QuickMatch <sup>TM</sup>',
      heading2:
        "In the next 90 seconds you'll learn everything you need to know about how Talkspace works and get matched with a provider that can help you.",
      buttonText: "Let's start!",
      buttonTarget: 2,
      nextQuestionActions: [skipToStepIfClientAgeExists(41, undefined, 40)],
    },
    {
      id: 2,
      prompt: 'What is your age?',
      analyticsId: 61,
      category: 'dateOfBirth',
      response_category_id: 5,
      progressCurrent: 1,
      progressMax: 10,
      heading2: 'To begin, please tell us your age.',
      targets: {
        under13: 'https://helpnow.talkspace.com/under-13',
        teen: 40,
        adult: 41,
      },
    },
    ...presentingProblemSteps(
      { singleStepID: 41, target: 135, multiStepID: 40 },
      {
        progressCurrent: 2,
        progressMax: 10,
        nextQuestionActions: [promptLastingPromotion, skipStepsForPsychiatryBH],
      }
    ),
    {
      id: 135,
      category: 'memberAvailability',
      prompt: 'When are you generally available for therapy?',
      heading2: 'When are you generally available for therapy?',
      skipQuestionActions: [
        skipToStepIfDoesntMeetAvailabilityPreferencesRequirements(4),
        skipIfExperiment({
          experimentName: 'clientAvailabilityPreferencesExperiment',
          variant: 'control',
          nextStepId: 4,
          shouldTrack: true,
        }),
      ],
      buttonTarget: 4,
    },
    {
      id: 3,
      prompt: 'Talkspace now offers guided couples therapy',
      analyticsId: 101,
      heading1: null,
      heading2: 'Talkspace now offers guided couples therapy',
      category: 'lastingPromotion',
      response_category_id: 7,
      progressCurrent: 3,
      progressMax: 10,
      externalTarget: 'https://lastingappstore.onelink.me/yxnG/f3c8f830',
      internalTarget: 4,
    },
    {
      id: 4,
      prompt: 'Here are things to know about Therapy',
      analyticsId: 56,
      category: 'explanation',
      progressCurrent: 3,
      progressMax: 10,
      response_category_id: 1,
      heading1: null,
      heading2: 'There are two important things to know about how Talkspace works',
      explanationGroups: [
        {
          text: 'You can send your provider text, audio and video messages whenever you want.',
          tooltipText:
            'This means that you will be communicating with your provider by typing in a secure chat room. Online therapy is a clinically valid and convenient way of working with a provider.',
        },
        {
          text: 'Your provider is available to engage daily, 5 days a week.',
          tooltipText:
            "You can write into the secure chat room whenever you feel you’re ready. You don't have to wait for your next appointment. If you need more or less, you can always arrange it with your provider.",
        },
      ],
      buttonText: 'Got it!',
      buttonTarget: 5,
    },
    {
      id: 5,
      prompt: 'Preferred provider gender',
      analyticsId: 57,
      category: 'select1',
      progressCurrent: 4,
      response_category_id: 3,
      progressMax: 10,
      heading1: null,
      heading2: 'Would you prefer a provider that is...',
      heading3: 'Please answer a few quick questions to narrow down your match.',
      buttons: [
        {
          text: 'Male',
          translationKey: 'quickmatch.flow138:step5.buttonMaleText',
          externalTarget: null,
          internalTarget: 6,
          answerValue: 1,
        },
        {
          text: 'Female',
          translationKey: 'quickmatch.flow138:step5.buttonFemaleText',
          externalTarget: null,
          internalTarget: 6,
          answerValue: 2,
        },
        {
          text: "I'm not sure yet",
          translationKey: 'quickmatch.flow138:step5.buttonNotSureText',
          externalTarget: null,
          internalTarget: 6,
          answerValue: undefined,
        },
      ],
      selfServe: {
        field: 'therapistGender',
      },
    },
    {
      id: 6,
      prompt: 'How familiar are you with therapy?',
      analyticsId: 58,
      category: 'select1',
      progressCurrent: 5,
      response_category_id: 7,
      progressMax: 10,
      heading2: 'Have you been to a provider before?',
      buttons: [
        {
          text: 'Yes',
          translationKey: 'quickmatch.flow138:step6.buttonYesText',
          externalTarget: null,
          internalTarget: 7,
          answerValue: 'Yes',
        },
        {
          text: 'No',
          translationKey: 'quickmatch.flow138:step6.buttonNoText',
          externalTarget: null,
          internalTarget: 7,
          answerValue: 'No',
        },
      ],
    },
    {
      id: 7,
      prompt: 'How would you rate your sleeping habits?',
      analyticsId: 59,
      category: 'select1',
      response_category_id: 7,
      progressCurrent: 6,
      progressMax: 10,
      heading2: 'How would you rate your sleeping habits?',
      buttons: [
        {
          text: 'Excellent',
          translationKey: 'quickmatch.flow138:step7.buttonExcellentText',
          externalTarget: null,
          internalTarget: 8,
          answerValue: 'Excellent',
        },
        {
          text: 'Good',
          translationKey: 'quickmatch.flow138:step7.buttonGoodText',
          externalTarget: null,
          internalTarget: 8,
          answerValue: 'Good',
        },
        {
          text: 'Fair',
          translationKey: 'quickmatch.flow138:step7.buttonFairText',
          externalTarget: null,
          internalTarget: 8,
          answerValue: 'Fair',
        },
        {
          text: 'Poor',
          translationKey: 'quickmatch.flow138:step7.buttonPoorText',
          externalTarget: null,
          internalTarget: 8,
          answerValue: 'Poor',
        },
      ],
    },
    {
      id: 8,
      prompt: 'How would you rate your physical health?',
      analyticsId: 60,
      category: 'select1',
      progressCurrent: 7,
      response_category_id: 7,
      progressMax: 10,
      heading2: 'How would you rate your current physical health?',
      buttons: [
        {
          text: 'Excellent',
          translationKey: 'quickmatch.flow138:step8.buttonExcellentText',
          externalTarget: null,
          internalTarget: 9,
          answerValue: 'Excellent',
        },
        {
          text: 'Good',
          translationKey: 'quickmatch.flow138:step8.buttonGoodText',
          externalTarget: null,
          internalTarget: 9,
          answerValue: 'Good',
        },
        {
          text: 'Fair',
          translationKey: 'quickmatch.flow138:step8.buttonFairText',
          externalTarget: null,
          internalTarget: 9,
          answerValue: 'Fair',
        },
        {
          text: 'Poor',
          translationKey: 'quickmatch.flow138:step8.buttonPoorText',
          externalTarget: null,
          internalTarget: 9,
          answerValue: 'Poor',
        },
      ],
    },
    {
      id: 9,
      prompt: 'My gender is',
      analyticsId: 62,
      category: 'select1',
      progressCurrent: 8,
      response_category_id: 4,
      progressMax: 10,
      heading2: 'Please select your gender.',
      buttons: [
        {
          text: 'Male',
          translationKey: 'quickmatch.flow138:step9.buttonMaleText',
          externalTarget: null,
          internalTarget: 10,
          answerValue: 1,
        },
        {
          text: 'Female',
          translationKey: 'quickmatch.flow138:step9.buttonFemaleText',
          externalTarget: null,
          internalTarget: 10,
          answerValue: 2,
        },
        {
          text: 'Transgender male',
          translationKey: 'quickmatch.flow138:step9.buttonTransgenderMaleText',
          externalTarget: null,
          internalTarget: 10,
          answerValue: 3,
        },
        {
          text: 'Transgender female',
          translationKey: 'quickmatch.flow138:step9.buttonTransgenderFemaleText',
          externalTarget: null,
          internalTarget: 10,
          answerValue: 4,
        },
        {
          text: 'Gender queer',
          translationKey: 'quickmatch.flow138:step9.buttonGenderQueerText',
          externalTarget: null,
          internalTarget: 10,
          answerValue: 5,
        },
        {
          text: 'Gender variant',
          translationKey: 'quickmatch.flow138:step9.buttonGenderVariantText',
          externalTarget: null,
          internalTarget: 10,
          answerValue: 6,
        },
        {
          text: 'Other',
          translationKey: 'quickmatch.flow138:step9.buttonOtherText',
          externalTarget: null,
          internalTarget: 10,
          answerValue: 7,
        },
        {
          text: 'Non-binary',
          translationKey: 'quickmatch.flow138:step9.buttonNonBinaryText',
          externalTarget: null,
          internalTarget: 10,
          answerValue: 8,
        },
      ],
      skipText: 'Skip',
      skipExternalTarget: null,
      skipInternalTarget: 10,
      nextQuestionActions: [skipToStepIfClientAgeIsTeen(16)],
    },
    {
      id: 16,
      prompt: 'Consent',
      analyticsId: 562,
      category: 'select1',
      heading2:
        'Based on your age and state, parent or guardian consent may be needed. Does this work for you?',
      buttons: [
        {
          text: 'Yes, I understand',
          translationKey: 'quickmatch.flow138:step16.buttonYesText',
          answerValue: 'Yes, I understand',
          externalTarget: null,
          internalTarget: 10,
        },
        {
          text: 'No, I need more information',
          translationKey: 'quickmatch.flow138:step16.buttonNoText',
          answerValue: 'No, I need more information',
          externalTarget: 'https://helpnow.talkspace.com/under-18-no-consent',
          internalTarget: null,
        },
      ],
      progressCurrent: 9,
      progressMax: 10,
    },
    {
      id: 10,
      prompt: 'What is your state?',
      analyticsId: 63,
      category: 'dropdown',
      progressCurrent: 9,
      doNotAllowSearch: false,
      response_category_id: 6,
      progressMax: 10,
      heading2: 'Please select your state of residence.',
      selectPlaceholder: 'Select a state',
      selectOptions: states,
      buttonText: 'Submit',
      buttonTarget: 12,
      skipExternalTarget: null,
      skipInternalTarget: 12,
      nextQuestionActions: {
        12: [routeToLanguageMatchingIfExperiment(190, 12)],
      },
    },
    {
      id: 190,
      prompt: 'What’s your preferred language?',
      analyticsId: 140,
      response_category_id: 7,
      category: 'selectDynamic',
      dataDependencies: [{ key: 'matchingLanguages', options: { forceRefetch: true } }],
      heading1: 'What’s your preferred language?',
      heading2: 'Based on the state you selected, there are providers who speak these languages:',
      buttons: languageMatchingButtons(191),
      skipQuestionActions: [languageMatchingSkipIfNoLanguages(12)],
      nextQuestionActions: {
        191: [
          (state, answer, flags) => {
            if (answer.response_value === LANGUAGE_ID_ENGLISH) {
              return 12;
            }
            return undefined;
          },
        ],
      },
      progressCurrent: 9,
      progressMax: 13,
    },
    {
      id: 191,
      // This prompt does not show to users, but is used in analytics, the "[language]" part is intentional
      prompt: 'Are you willing to wait for a [language]-speaking provider?',
      analyticsId: 141,
      response_category_id: 7,
      category: 'selectDynamic',
      selfServe: languageMatchingSelfServe(190, 191),
      heading1: languageMatchingFilterHeading1(190),
      heading2: languageMatchingFilterHeading2(190),
      buttons: languageMatchingFilterButtons(12),
      skipQuestionActions: [skipToStepIfAnswerValue(12, `${LANGUAGE_ID_ENGLISH}`)],
      progressCurrent: 10,
      progressMax: 13,
    },
    {
      id: 12,
      prompt: 'Loading',
      category: 'loading1',
      buttonTarget: 13,
      hideBackButton: true,
    },
    {
      id: 13,
      prompt: 'Matches',
      category: 'matches1',
      buttonTarget: 20,
      zeroMatchTarget: 31,
      nextQuestionActions: {
        31: [skipReviewPlanIfIrrelevant],
      },
    },
    {
      id: 20,
      category: 'matchConfirmation',
      buttonTarget: 21,
    },
    {
      id: 30,
      category: 'sessionModality',
      buttonTarget: 19,
    },
    {
      id: 31,
      category: 'reviewPlan',
      buttonTarget: 30,
      nextQuestionActions: [skipModalitySelectionIfIrrelevant],
    },
    {
      id: 19,
      category: 'registerWithVoucher1',
      hideBackButton: true,
    },
    {
      id: 21,
      prompt: 'Scheduler',
      category: 'scheduler',
      buttonTarget: 31,
      nextQuestionActions: [skipReviewPlanIfIrrelevant],
    },
  ],
};

export default flow;
