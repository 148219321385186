import { TFQuickmatchCommon, TranslationKey } from '@talkspace/i18n/types';

const talkspaceTherapySessionParagraphForBH =
  'Each session is equivalent to either a full length live session or text messaging with your Provider. On average, text messaging sessions last 7 days. You will be billed on a per session basis, subject to the same co-pay or co-insurance (after deductible) that would apply to an in-person visit.';

const talkspaceTherapySessionParagraphForEAP =
  'Each session is equivalent to either a full length live session or one week of messaging with your Provider – it’s your choice. Sessions are free because your employer has already paid for them via their Employee Assistance Program (EAP).';

const insurancePolicyParagraphForBH = (
  <>
    Your co-pay may not be accurately displayed. Our system tries to get the most up-to-date
    information from your insurer, but actual amounts may vary. If your plan has a cost-share that
    is different from what is shown, the charge will be adjusted after each session is processed.
    <br />
    <br />
    If your insurance coverage changes or your insurer rejects your claim, you could owe up to this
    amount per session.
  </>
);

const cancellationPolicyParagraphForBH = (
  <>
    You can cancel or reschedule for free up until 24 hours before your scheduled appointment start
    time. <br /> <br />
    Keep in mind that late cancellations (24 hours or less) and no-shows result in a fee for the
    cost of service, which can be up to $175. If you use credits for sessions, late cancellations
    and no-shows will also result in a redeemed credit.
  </>
);

const cancellationPolicyParagraphForEAP = `You can cancel or reschedule for free anytime before your scheduled session start time. We ask that you please keep in mind that your provider's time is valuable and they are not paid for late cancellations.`;

export enum ReviewPlans {
  PLAN_REVIEW_BH = 'PLAN_REVIEW_BH',
  PLAN_REVIEW_BH_VIDEO = 'PLAN_REVIEW_BH_VIDEO',
  PLAN_REVIEW_EAP_VIDEO_PLUS_MESSAGE = 'PLAN_REVIEW_EAP_VIDEO_PLUS_MESSAGE',
  PLAN_REVIEW_EAP_MESSAGE = 'PLAN_REVIEW_EAP_MESSAGE',
  PLAN_REVIEW_EAP_VIDEO = 'PLAN_REVIEW_EAP_VIDEO',
}

export const PLAN_REVIEW_THERAPIST_BULLET_THERAPIST_MATCHED = 'A therapist matched to your needs';
export const PLAN_REVIEW_THERAPIST_BULLET_THERAPY_WITH = 'Therapy with';

type PlanReview = {
  category: ReviewPlans;
  isBH?: boolean;
  titleText: string;
  titleTextTranslationKey: TranslationKey;
  bullets: string[];
  bulletsTranslationKeys: TranslationKey[];
  disclaimerText?: string;
  disclaimerTextTranslationKey: TranslationKey;
  importantToKnow?: boolean;
  modalDetails: {
    topText: string;
    topTextTranslationKey: TranslationKey;
    paragraphs: {
      title: string;
      titleTranslationKey: TranslationKey;
      description: string | JSX.Element;
      descriptionTranslationKey: TranslationKey;
    }[];
  };
};
export const PLAN_REVIEW_BH: PlanReview = {
  category: ReviewPlans.PLAN_REVIEW_BH,
  isBH: true,
  titleText: 'Your coverage details',
  titleTextTranslationKey: 'reviewPlanStep.titleCoveredByInsurance',
  bullets: ['<!therapistBulletText>', 'Unlimited sessions', 'Video and messaging available'],
  bulletsTranslationKeys: [
    'reviewPlanStep.bulletTherapistCustom',
    'reviewPlanStep.bulletSessionCountUnlimited',
    'reviewPlanStep.bulletModalityVideoAndMessaging',
  ],
  disclaimerTextTranslationKey: 'reviewPlanStep.disclaimerBHText',
  importantToKnow: true,
  modalDetails: {
    topText: 'Session details',
    topTextTranslationKey: 'reviewPlanStep.modalTopText',
    paragraphs: [
      {
        title: 'Talkspace Therapy Session',
        titleTranslationKey: 'reviewPlanStep.paragraphTalkspaceSessionTitle',
        description: talkspaceTherapySessionParagraphForBH,
        descriptionTranslationKey: 'reviewPlanStep.talkspaceTherapySessionParagraphForBH',
      },
      {
        title: 'Insurance details',
        titleTranslationKey: 'reviewPlanStep.paragraphInsuranceDetailsTitle',
        description: insurancePolicyParagraphForBH,
        descriptionTranslationKey: 'reviewPlanStep.insurancePolicyParagraphForBH',
      },
      {
        title: 'Cancellation policy',
        titleTranslationKey: 'reviewPlanStep.paragraphCancellationPolicyTitle',
        description: cancellationPolicyParagraphForBH,
        descriptionTranslationKey: 'reviewPlanStep.cancellationPolicyParagraphForBH',
      },
    ],
  },
};

export const PLAN_REVIEW_BH_VIDEO: PlanReview = {
  category: ReviewPlans.PLAN_REVIEW_BH_VIDEO,
  isBH: true,
  titleText: 'Your coverage details',
  titleTextTranslationKey: 'reviewPlanStep.titleCoveredByInsurance',
  bullets: ['<!therapistBulletText>', 'Unlimited sessions', 'Connect over live video and audio'],
  bulletsTranslationKeys: [
    'reviewPlanStep.bulletTherapistCustom',
    'reviewPlanStep.bulletSessionCountUnlimited',
    'reviewPlanStep.bulletModalityLiveVideoAndAudio',
  ],
  disclaimerTextTranslationKey: 'reviewPlanStep.disclaimerBHText',
  importantToKnow: true,
  modalDetails: {
    topText: 'Session details',
    topTextTranslationKey: 'reviewPlanStep.modalTopText',
    paragraphs: [
      {
        title: 'Talkspace Therapy Session',
        titleTranslationKey: 'reviewPlanStep.paragraphTalkspaceSessionTitle',
        description: talkspaceTherapySessionParagraphForBH,
        descriptionTranslationKey: 'reviewPlanStep.talkspaceTherapySessionParagraphForBH',
      },
      {
        title: 'Insurance details',
        titleTranslationKey: 'reviewPlanStep.paragraphInsuranceDetailsTitle',
        description: insurancePolicyParagraphForBH,
        descriptionTranslationKey: 'reviewPlanStep.insurancePolicyParagraphForBH',
      },
      {
        title: 'Cancellation policy',
        titleTranslationKey: 'reviewPlanStep.paragraphCancellationPolicyTitle',
        description: cancellationPolicyParagraphForBH,
        descriptionTranslationKey: 'reviewPlanStep.cancellationPolicyParagraphForBH',
      },
    ],
  },
};

export const PLAN_REVIEW_EAP_VIDEO_PLUS_MESSAGE: PlanReview = {
  category: ReviewPlans.PLAN_REVIEW_EAP_VIDEO_PLUS_MESSAGE,
  titleText: "You're covered by your employer!",
  titleTextTranslationKey: 'reviewPlanStep.titleCoveredByEmployer',
  bullets: [
    'A therapist matched to your needs',
    '<!totalSessions> therapy sessions',
    'No cost to you',
    'Video and messaging available',
  ],
  bulletsTranslationKeys: [
    'reviewPlanStep.bulletTherapist',
    'reviewPlanStep.bulletSessionCount',
    'reviewPlanStep.bulletNoCost',
    'reviewPlanStep.bulletModalityVideoAndMessaging',
  ],
  disclaimerText:
    '*Sessions are free because your employer has already paid for them via their Employee Assistance Program (EAP)',
  disclaimerTextTranslationKey: 'reviewPlanStep.disclaimerEAPText',
  modalDetails: {
    topText: 'Session details',
    topTextTranslationKey: 'reviewPlanStep.modalTopText',
    paragraphs: [
      {
        title: 'Talkspace Therapy Session',
        titleTranslationKey: 'reviewPlanStep.paragraphTalkspaceSessionTitle',
        description: talkspaceTherapySessionParagraphForEAP,
        descriptionTranslationKey: 'reviewPlanStep.talkspaceTherapySessionParagraphForEAP',
      },
      {
        title: 'Cancellation policy',
        titleTranslationKey: 'reviewPlanStep.paragraphCancellationPolicyTitle',
        description: cancellationPolicyParagraphForEAP,
        descriptionTranslationKey: 'reviewPlanStep.cancellationPolicyParagraphForEAP',
      },
    ],
  },
};

export const PLAN_REVIEW_EAP_MESSAGE: PlanReview = {
  category: ReviewPlans.PLAN_REVIEW_EAP_MESSAGE,
  titleText: 'You’re covered by your employer!',
  titleTextTranslationKey: 'reviewPlanStep.titleCoveredByEmployer',
  bullets: [
    'A therapist matched to your needs',
    '<!totalSessions> therapy sessions',
    'No cost to you',
    'In-app texting with therapist responses five days per week',
  ],
  bulletsTranslationKeys: [
    'reviewPlanStep.bulletTherapist',
    'reviewPlanStep.bulletSessionCount',
    'reviewPlanStep.bulletNoCost',
    'reviewPlanStep.bulletModalityMessaging',
  ],
  disclaimerText:
    '*Sessions are free because your employer has already paid for them via their Employee Assistance Program (EAP)',
  disclaimerTextTranslationKey: 'reviewPlanStep.disclaimerEAPText',
  modalDetails: {
    topText: 'Session details',
    topTextTranslationKey: 'reviewPlanStep.modalTopText',
    paragraphs: [
      {
        title: 'Talkspace Therapy Session',
        titleTranslationKey: 'reviewPlanStep.paragraphTalkspaceSessionTitle',
        description: talkspaceTherapySessionParagraphForEAP,
        descriptionTranslationKey: 'reviewPlanStep.talkspaceTherapySessionParagraphForEAP',
      },
      {
        title: 'Cancellation policy',
        titleTranslationKey: 'reviewPlanStep.paragraphCancellationPolicyTitle',
        description: cancellationPolicyParagraphForEAP,
        descriptionTranslationKey: 'reviewPlanStep.cancellationPolicyParagraphForEAP',
      },
    ],
  },
};

export const PLAN_REVIEW_EAP_VIDEO: PlanReview = {
  category: ReviewPlans.PLAN_REVIEW_EAP_VIDEO,
  titleText: "You're covered by your employer!",
  titleTextTranslationKey: 'reviewPlanStep.titleCoveredByEmployer',
  bullets: [
    'A therapist matched to your needs',
    '<!totalSessions> therapy sessions',
    'No cost to you',
    'Video available',
  ],
  bulletsTranslationKeys: [
    'reviewPlanStep.bulletTherapist',
    'reviewPlanStep.bulletSessionCount',
    'reviewPlanStep.bulletNoCost',
    'reviewPlanStep.bulletModalityVideo',
  ],
  disclaimerText:
    '*Sessions are free because your employer has already paid for them via their Employee Assistance Program (EAP)',
  disclaimerTextTranslationKey: 'reviewPlanStep.disclaimerEAPText',
  modalDetails: {
    topText: 'Session details',
    topTextTranslationKey: 'reviewPlanStep.modalTopText',
    paragraphs: [
      {
        title: 'Talkspace Therapy Session',
        titleTranslationKey: 'reviewPlanStep.paragraphTalkspaceSessionTitle',
        description: talkspaceTherapySessionParagraphForEAP,
        descriptionTranslationKey: 'reviewPlanStep.talkspaceTherapySessionParagraphForEAP',
      },
      {
        title: 'Cancellation policy',
        titleTranslationKey: 'reviewPlanStep.paragraphCancellationPolicyTitle',
        description: cancellationPolicyParagraphForEAP,
        descriptionTranslationKey: 'reviewPlanStep.cancellationPolicyParagraphForEAP',
      },
    ],
  },
};

const CONSUMER_THERAPY_FLOW_ID_114 = 114; // Online therapy - single plan experiment flowID
const CONSUMER_THERAPY_FLOW_ID_132 = 132; // Online therapy
const COUPLES_THERAPY_FLOW_ID_67 = 67; // Couples Therapy
const TEEN_THERAPY_FLOW_ID_97 = 97; // Teens therapy

export const isNonPsychiatry = (flowID) =>
  [
    CONSUMER_THERAPY_FLOW_ID_132,
    COUPLES_THERAPY_FLOW_ID_67,
    TEEN_THERAPY_FLOW_ID_97,
    CONSUMER_THERAPY_FLOW_ID_114,
  ].includes(flowID);

export const parseTextInBullets = ({
  bullets,
  bookedTherapistName,
  bookedTherapistLicense,
  totalSessions,
  bulletsTranslationKeys,
  tQuickmatchCommon,
}: {
  bullets: string[];
  bookedTherapistName?: string;
  bookedTherapistLicense?: string;
  totalSessions?: number;
  bulletsTranslationKeys: TranslationKey[];
  tQuickmatchCommon: TFQuickmatchCommon;
}) => {
  if (!bullets) {
    return undefined;
  }

  let therapistBulletText;

  if (bookedTherapistName) {
    const licenseString = bookedTherapistLicense ? `, ${bookedTherapistLicense}` : '';
    therapistBulletText = `${PLAN_REVIEW_THERAPIST_BULLET_THERAPY_WITH} ${bookedTherapistName}${licenseString}`;
  } else {
    therapistBulletText = PLAN_REVIEW_THERAPIST_BULLET_THERAPIST_MATCHED;
  }

  const translatedBullets = bullets.map((bullet, i) =>
    (tQuickmatchCommon(bulletsTranslationKeys[i], bullet, undefined) as string) // NOTE: undefined intentional
      .replace('<!totalSessions>', `${totalSessions || 10}`)
      .replace('<!therapistBulletText>', therapistBulletText)
  );

  return translatedBullets;
};

export const getBHPlanReviewByPlan = (isVideoOnlyPlan: boolean) => {
  if (isVideoOnlyPlan) {
    return PLAN_REVIEW_BH_VIDEO;
  }
  return PLAN_REVIEW_BH;
};
