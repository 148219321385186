import {
  SESSION_STORAGE_MEMBER_DETAILS_KEY,
  getSessionStorageValuesFromJson,
} from 'ts-frontend/utils';
import PreRegisterExpired from '../PreRegisterExpired';

const PreRegisterHoldExpired = () => {
  const { referralSource } = getSessionStorageValuesFromJson(SESSION_STORAGE_MEMBER_DETAILS_KEY, [
    'referralSource',
  ]);

  let heading = 'Sorry, your reservation has expired';

  const isZocdoc = referralSource?.value === 'zocdoc';

  if (isZocdoc) {
    heading = 'Sorry, your ZocDoc reservation has expired';
  }

  return (
    <PreRegisterExpired
      heading={heading}
      subHeading="It’s been too long between booking your appointment on Zocdoc and completing the Talkspace onboarding. Don't worry, we'll help you explore other options."
    />
  );
};

export default PreRegisterHoldExpired;
